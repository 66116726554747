import {useEffect, useRef, useState, useCallback, useContext} from "react"
import {useLocation, useNavigate} from "react-router-dom"
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import 'react-calendar/dist/Calendar.css';
import Calendar from 'react-calendar';
import LinkedDepositList from "../../components/customer/deposit/LinkedDepositList";
import {faFloppyDisk, faMoneyCheckDollar} from "@fortawesome/free-solid-svg-icons";
import EditingMainHeader from "../../components/addOns/headers/EditingMainHeader";
import {GetApiContext} from "../../context/apiCalls/getApiCalls";
import {useAuthState} from "../../context/authContext";
import {PostApiContext} from "../../context/apiCalls/postApiCalls";
import Loader from "../../components/addOns/loader";
import {getCurrentDate} from "../../utils/utils";

export default function Deposit() {

    const navigate = useNavigate()
    const idPage = useLocation().pathname.split("/")[3].split("-")[1];
    let id = useLocation().pathname.split("/")[3].split("-")[0];
    const getApiContext = useContext(GetApiContext);
    let postApiContext = useContext(PostApiContext);
    const {roleId} = useAuthState();

    // Ref
    const [ref, setRef] = useState("");
    const refRef = useRef("")
    const rateRef = useRef(null)
    const totalTTCRef = useRef(null)
    const titleRef = useRef(null)
    const descRef = useRef(null)
    const dateRef = useRef(null)
    let oldId = useRef(null);


    // State
    const [depositDate, setDepositDate] = useState(null)
    const [payementDate, setPayementDate] = useState(null)
    const [calendarPop, setCalendarPop] = useState(false)
    const [buttonId, setbuttonId] = useState(null)
    const [data, setData] = useState(undefined)
    const [dataContact, setDataContact] = useState(null)
    const [dataQuoteInvoice, setDataQuoteInvoice] = useState(null)
    const [quote, setQuote] = useState(null)
    const [invoice, setInvoice] = useState(null)
    const [totalTtc, setTotalTtc] = useState(0)
    const [showCalendar, setShowCalendar] = useState(false);
    const [loading, setLoading] = useState(true);


    const [rate, setRate] = useState(0)
    const [linkDeposit, setLinkDeposit] = useState([])
    const [totalLinkDeposit, setTotalLinkDeposit] = useState(0)
    const [leftToPay, setLeftToPay] = useState(null)
    const [depositTotalTtc, setDepositTotalTtc] = useState(0)
    const [calc, setCalc] = useState(null)


    const fetchDepositData = useCallback(async () => {
        try {
            if (!(id === oldId.current)){
                oldId.current = id;
            setLoading(true);
            let idQuote = null;
            let idInvoice = null;
            let idDeposit = null;
            if (idPage === 'quote') {
                idQuote = id;
            } else if (idPage === 'invoice') {
                idInvoice = id;
            } else {
                idDeposit = id;
            }
            getApiContext.getDeposit(idDeposit, idQuote, idInvoice).then(response => {
                console.log("id", id, "idpage", idPage);
                const depositData = response?.deposit?.deposit || null;
                setData(depositData);
                setRef(depositData?.ref || "");
                setInvoice(depositData?.invoice || null);
                setQuote(depositData?.quote || null);
                setDataQuoteInvoice(depositData?.invoice || depositData?.quote || null);
                setLinkDeposit(response?.linkDeposit);
                setLoading(false);
                dateRef.current = depositData?.depositDate || getCurrentDate();

})}
        } catch (error) {
            console.error(error);
        }
    }, [getApiContext, id, idPage]);

    useEffect(() => {
        fetchDepositData();
    }, [fetchDepositData]);

    useEffect(() => {
        setTotalTtc(invoice?.totalTtc ?? quote?.totalTtc ?? data?.quote?.totalTtc ??data?.totalTtc ?? 0);
        const totalLinkDepositMap = linkDeposit?.map(element => element.totalTtc) ?? [];
        const sommeTotalTtc = totalLinkDepositMap.map(Number).reduce((a, b) => a + b, 0);
        setTotalLinkDeposit(sommeTotalTtc)

        setCalc(totalTtc - sommeTotalTtc);
        setLeftToPay(totalTtc - sommeTotalTtc);

        setRate(data?.rate ?? 0)
        setDepositTotalTtc(data?.totalTtc ?? 0)


        if (totalTtc && totalTtc.toString() === '0') {
            const rateData = data?.deposit?.rate
            const calcTtc = (rateData * calc) / 100
            setDepositTotalTtc(calcTtc?.toFixed(2))
        }

        if (!dataContact){
        setDataContact(data?.quote?.contact ?? data?.invoice?.contact ?? null)
        }
    }, [data, quote, invoice, linkDeposit, id, totalTtc, dataContact, calc]);


    const handleCalc = (e) => {
        e.preventDefault()
        if (e.target.id === 'totalDepositInput') {
            const totalCalc = totalTtc - totalLinkDeposit - e.target.value
            const rateCalc = (e.target.value / totalTtc) * 100
            setRate(rateCalc.toFixed(2) || 0)
            setLeftToPay(totalCalc)
            setDepositTotalTtc(e.target.value)
        } else if (e.target.id === 'rateInput') {
            const rateCalc = (totalTtc * e.target.value) / 100
            const rate = totalTtc - totalLinkDeposit - rateCalc
            setRate(e.target.value);
            setDepositTotalTtc(rateCalc?.toFixed(2))
            setLeftToPay(rate)
        }

    }

    const onSubmit = (e) => {
        //console.log("deposit submit")
        let path = idPage === 'quote' ? `/quotesList/quote/${quote?.id}` : idPage === 'invoice' ? `/invoicesList/invoice/${invoice?.id}` : '/depositsList/'
        onValidDeposit(e, path);
        fetchDepositData();
    }

    const createDeposit = (e) => {
        e.preventDefault()
        const values = {
            idInvoice: invoice?.id,
            idQuote: quote?.id,
            ref: linkDeposit.length + 1,
            depositDate: new Date(),
        }

        postApiContext.postDeposit(values)
            .then(response => {
                navigate(`/depositsList/deposit/${response.data.deposit.id}`);
            })
            .catch(error => {
                console.log(error);
            })
    }

const onValidDeposit = (e, path = `/depositsList/deposit/${localStorage.getItem("parentPath").includes("invoice") ? invoice?.id : quote?.id}-${localStorage.getItem("parentPath").includes("invoice") ? "invoice" : "quote"}`) => {
    e.preventDefault()
    console.log(path)
    const values = {
        idDeposit: data?.id ?? null,
        idQuote: data?.quote?.id || (idPage === "quote" ? id : null),
        idInvoice: data?.invoice?.id || (idPage === "invoice" ? id : null),
        ref: refRef.current.value,
        depositDate: dateRef.current ?? depositDate,
        paymentDate: payementDate ? payementDate : data?.paymentDate,
        rate: rateRef.current.value,
        totalTTC: totalTTCRef.current.value,
        subject: titleRef?.current?.value,
        comment: descRef?.current?.value,
    }

    postApiContext.postDeposit(values)
    //axios.post(`${process.env.REACT_APP_DEPOSIT_QUOTE_POST}`, values, customHeaders,)
        .then(response => {
            document.querySelector("form").reset();
            fetchDepositData();
            handleNavigate(path);
            window.location.reload();
        })
        .catch(error => {
            console.log(error);
        })
}

const handleNavigate = (path = "") => {
    let newPath = localStorage.getItem("parentPath");
    navigate(path ?? newPath ?? "/depositsList");
}

function handleNavigateFunc(path = "") {
    let newPath = localStorage.getItem("parentPath");
    return newPath ?? path ?? "/depositsList";
}

const handleDelete = (depositId) => {
    //e.preventDefault()
    console.log("delete", depositId)
    postApiContext.desactivateDeposit(depositId)
        .then(response => {
            fetchDepositData();
            window.location.reload();
        })
    if (depositId === id) {
        navigate(handleNavigateFunc())
    }
}

const filterDate = (date) => {
    const year = date.getFullYear()
    const month = date.getMonth() + 1
    const day = date.getDate()
    return `${year}-${month}-${day}`
}

const handleClick = (e) => {
    const id = e.target.id
    if (id === 'depositDate') {
        setCalendarPop(true)
        setbuttonId('1')
    } else if (id === 'payementDate') {
        setCalendarPop(true)
        setbuttonId('2')
    }
}

const handleChange = (e) => {
    if (buttonId === '1') {
        setDepositDate(filterDate(e))
    } else {
        setPayementDate(filterDate(e))
    }
}

    const headerButtonsList={
        mainButton: {
            buttonIcon: faFloppyDisk,
            buttonText: id ? "Sauvegarder" : "Créer le devis",
            buttonAction: (e) => onValidDeposit(e, handleNavigateFunc()),
        },
    }

return (
    <>
        {
            loading &&
            <Loader/>
        }
        {calendarPop &&
            <div onClick={() => setCalendarPop(false)} className="grayBlur"></div>
        }
        {calendarPop &&
        <>
            <Calendar onChange={handleChange} />
        </>}
        <EditingMainHeader headerTitle={"Acompte"} dateRef={dateRef} setShowCalendar={setShowCalendar} showCalendar={showCalendar}
                           destination={handleNavigateFunc()} endPoint={"c-deactive-deposit"}
                           id={id} refRef={refRef} defaultRef={ref} roleId={roleId}
                           headerButtonsList={headerButtonsList}
                           backRedirect={handleNavigateFunc()}/>

        <form onSubmit={onSubmit} className="listPage">
                    <div className="block">
                        <div className="editingInfos">
                            <div className="editingInfos__titleContainer">
                                <h2 className="editingInfos__titleContainer_title">informations</h2>
                                <hr className="editingInfos__titleContainer_line"/>
                            </div>
                            <div className='editingInfos__firstSection'>
                                <div className="editingInfos__firstSection_nameContainer">
                                    <h3 className="editingInfos__firstSection_nameContainer_title">Intitulé de
                                        l'acompte</h3>
                                    <input className={"editingInfos__firstSection_nameContainer_input"} defaultValue={data?.subject} ref={titleRef} placeholder='Voiture'/>
                                </div>
                                <div className="editingInfos__firstSection_interContainer">
                                    <h3 className="editingInfos__firstSection_interContainer_title">{data?.quote ? "Devis" : "Facture"}</h3>
                                    <div className="editingInfos__firstSection_interContainer_content">
                                        <p>{data?.quote ? "Devis" : "Facture"} N°<span>{dataQuoteInvoice?.ref}</span></p>
                                    </div>
                                </div>
                            </div>
                            <div className="editingInfos__contact">
                                <h3 className="editingInfos__contact_title">Destinataire</h3>
                                <p className="ref">CONTACT N°<span>{dataContact?.ref || dataContact?.id}</span></p>
                                <p className="name">{dataContact?.firstName || dataContact?.first_name} {dataContact?.lastName && dataContact?.firstName ? "-" : ""} {dataContact?.lastName || dataContact?.last_name}</p>
                                <p className="address">{dataContact?.adress1}</p>
                            </div>
                            <hr/>
                        </div>
                        <div className="contract-body">
                            <div className="container">
                                <div className="deposit">
                                    <div>
                                        <label htmlFor="deposit-percent">Pourcentage de l'acompte</label>
                                        <div>
                                            <input
                                                ref={rateRef}
                                                id="rateInput"
                                                onChange={handleCalc}
                                                value={rate}
                                                min={0}
                                                step="0.01"
                                                max={100}
                                                type="number"
                                                placeholder="Ex : 63"
                                            /> %
                                        </div>
                                    </div>
                                    <div>
                                        <label htmlFor="deposit-amount">Montant de l'acompte</label>
                                        <div>
                                            <input
                                                id="totalDepositInput"
                                                ref={totalTTCRef}
                                                onChange={handleCalc}
                                                min={0} step={0.01}
                                                value={depositTotalTtc}
                                                type="number"
                                                placeholder='Ex : 523.25'
                                            /> €
                                        </div>
                                    </div>
                                </div>
                                <div className="comment">
                                    <h3>COMMENTAIRE AJOUTE A L'ACOMPTE</h3>
                                    <textarea ref={descRef} defaultValue={data?.comment}
                                              placeholder="Description"></textarea>
                                </div>
                            </div>
                        </div>
                        <div className="aside">
                        <div className="marginPrice">
                            <div className="marginPrice__bold-block">
                                <p>Total TTC du devis</p>
                                <p>{totalTtc} €</p>
                            </div>
                            <div className="marginPrice__bold-block">
                                <p>Total de l'acompte</p>
                                <p>{depositTotalTtc || "0"} €</p>
                            </div>
                            <div className="marginPrice__tva">
                                <div>
                                    <p>Total des acomptes</p>
                                    <p className="depositTvaAmount">{totalLinkDeposit.toFixed(2) || 0} €</p>
                                </div>
                            </div>
                            <div className="marginPrice__bold-block">
                                <p>Reste de la facture à payer</p>
                                <p>{leftToPay?.toFixed(2)} €</p>
                            </div>
                            <div className="payementDate">

                                <p><FontAwesomeIcon icon={faMoneyCheckDollar}/>Suivi de règlement :</p>
                                <div>Acompte payé le
                                    <p className="calendarLogoBg " onClick={handleClick}>
                                        <img className="calendarLogo" id="payementDate"
                                             src="/calendar-days-solid.svg" alt=""/>
                                    </p>

                                    <span>&thinsp;&thinsp;&thinsp;{payementDate ? payementDate : data?.paymentDate}</span>

                                </div>
                            </div>
                            <LinkedDepositList
                                origin={"quote"}
                                linkDeposit={linkDeposit}
                                deleteDeposit={handleDelete}
                                quote={quote || invoice || null}
                                onSubmit={onValidDeposit}
                                createDeposit={createDeposit}
                            />
                        </div>
                        </div>
                    </div>
            </form>

        </>
    )
}