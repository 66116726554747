import {useContext, useEffect, useRef, useState} from "react";
import MainHeader from "../../../components/addOns/headers/MainHeader";
import SortByFilter from "../../../components/addOns/filterComponent/SortByFilter";
import SearchComponent from "../../../components/addOns/filterComponent/searchComponent";
import { useNavigate } from "react-router-dom";
import {GetApiContext} from "../../../context/apiCalls/getApiCalls";
import {PostApiContext} from "../../../context/apiCalls/postApiCalls";
import {faCopy, faDownload, faFileMedical, faPaperPlane, faPrint} from "@fortawesome/free-solid-svg-icons";
import Loader from "../../../components/addOns/loader";
import Card from "../../../components/addOns/card";
import roleVerify from "../../../utils/roleVerify";
import {useAuthState} from "../../../context/authContext";

function DeliveriesList() {

    let navigate = useNavigate();

    const getApiContext = useContext(GetApiContext);
    const postApiContext = useContext(PostApiContext);
    const {roleId} = useAuthState();
    const roleList = ["1", "2", "3", "4", "5", "6"];
    const roleVerif = roleVerify(roleList, roleId)

    const [deliveries, setDeliveries] = useState();
    const [reload, setReload] = useState();
    const [sort, setSort] = useState('refup');
    const [loading, setLoading] = useState(true);
    const [listRefresh, setListRefresh] = useState(false);

    useEffect(() => {
        setLoading(true);
        getApiContext.getDeliveriesList()
        //axios.get(`${process.env.REACT_APP_DELIVERY_LIST}`)
            .then(response => {
                // console.log("response", response)
                setDeliveries(response.deliveriesList);
                setLoading(false);
                setListRefresh(false)
            })
            .catch(error => {
                console.log("deliveryList get error:", error);
            })
    }, [getApiContext, listRefresh])

    const refresh = () => {
        const formulaire = document.getElementById("searchFilterForm");
        formulaire.reset();
        //setSort("refup");
        setReload(true);
    }

    //const for research component
    const firstNameRef = useRef();
    const lastNameRef = useRef();
    const societyRef = useRef();
    const invoiceFilterRef = useRef();
    const deliveryRef = useRef();

    const headerContent = {
        ContentName: "BON DE LIVRAISON",
        Content: [
            { name: "firstName", title: "Prénom", type: "text", ref: firstNameRef },
            { name: "lastName", title: "Nom", type: "text", ref: lastNameRef },
            { name: "society", title: "Société", type: "text", ref: societyRef },
            { name: "refFacture", title: "Référence facture", type: "text", ref: invoiceFilterRef },
            { name: "refDelivery", title: "Référence bon de livraison", type: "text", ref: deliveryRef }
        ]
    }

    useEffect(() => {

        //setMargins(null);
        setLoading(true);

        const values = {
            firstName: firstNameRef?.current?.value,
            lastName: lastNameRef?.current?.value,
            society: societyRef?.current?.value,
            refInvoice: invoiceFilterRef?.current?.value,
            deliveryRef: deliveryRef?.current?.value,
            sort: sort,
        }

        postApiContext.postDeliveriesFilterList(values)
            .then(response => {
                // console.log("response post", response)
                setDeliveries(response.deliveriesList);
                setLoading(false);
                //setNbPages(response.data.nbPages);
            })
            .catch(error => {
                console.log("erreur :", error);
            })
        setReload(false); 
    }, [sort, reload, postApiContext])

    const onValidFilter = async (e) => {
        e.preventDefault();
        setReload(true);
    }

    /**
     * options for SortByFilter
     */
    const sortOptions = {
        options: [
            { value: "refup", content: "Références croissantes" },
            { value: "refdown", content: "Références décroissantes" },
            { value: "dateup", content: "Date de création croissante" },
            { value: "datedown", content: "Date de création décroissante" },
        ]
    }

    const objectButtons = [
        {title: "Nouveau bon de livraison", className: "", icon: faFileMedical, action: () => navigate("delivery/"), showByRoleId: true},
    ]

    const otherButtonList = [
        {title: "Télécharger", icon: faDownload},
        {title: "Envoyer", icon: faPaperPlane},
        {title: "imprimer", icon: faPrint},
        {title: "dupliquer", icon: faCopy, action: (e) => console.log("hello")},
    ]

    return (
        <>
            <MainHeader
                headerTitle={"BONS DE LIVRAISON"} objectButtons={objectButtons}
            />

            {
                loading &&
                <Loader/>
            }

            <div className="listPage">
                <div className="listPage__filterContainer">
                    <SearchComponent
                        refresh={refresh}
                        onSearchSelectChange={""}
                        onSearchSubmit={onValidFilter}
                        contentObject={headerContent}
                        interlocutor={""}
                        className={"without-stateFilter"}
                    />

                    <SortByFilter
                        sortOptions={sortOptions}
                        setSort={setSort}
                        onFilterSubmit={onValidFilter}
                    />
                </div>

                {deliveries &&
                    deliveries?.map((delivery, index) => {
                        console.log("delivery", delivery?.invoice?.totalHt)
                        const element = {
                            id: delivery.id,
                            date: delivery.date,
                            ref: delivery.ref,
                            subject: delivery.subject,
                            lastName: delivery.contact?.lastName,
                            firstName: delivery.contact?.firstName,
                            society: delivery.contact?.society,
                            directionPath: "/invoicesList/deliveriesList/delivery/",
                            totalHt: delivery?.invoice?.totalHt,
                            totalTtc: delivery?.invoice?.totalTtc,
                            //initials: quote.userInitials,
                        }

                        return (
                            <Card roleVerif={roleVerif} key={index} element={element} tableName={"delivery"}
                                  stateButtonsList={[]} setListRefresh={setListRefresh}
                                  deleteEndPoint={"c-deactive-delivery"} otherButtonList={otherButtonList}/>
                        )
                    })}
            </div>
        </>
    );
}
export default DeliveriesList;