import Invoice from "../routes/invoice/invoice";
import InvoiceList from "../routes/invoice/invoicesList";
import Delivery from "../routes/invoice/delivery/Delivery";
import DeliveriesList from "../routes/invoice/delivery/DeliveriesList";
import PrivateRouter from "../privateRouter";

const roles = ["1", "2", "3", "4", "5", "6"];

export const invoicePaths = [
    {
        path: "invoicesList",
        element: <PrivateRouter roleList={roles}><InvoiceList /></PrivateRouter>,
    },
    {
        path: "invoicesList/invoice/:id",
        element:<PrivateRouter roleList={roles}><Invoice /></PrivateRouter>,
    },
    {
        path: "invoicesList/invoice",
        element: <PrivateRouter roleList={roles}><Invoice /></PrivateRouter>,
    },
    {
        path: "invoicesList/deliveriesList/delivery/:id",
        element: <PrivateRouter roleList={roles}><Delivery /></PrivateRouter>,
    },
    {
        path: "invoicesList/deliveriesList/delivery/",
        element:  <PrivateRouter roleList={roles}><Delivery /></PrivateRouter>,
    },
    {
        path: "invoicesList/deliveriesList",
        element: <PrivateRouter roleList={roles}><DeliveriesList /></PrivateRouter>,
    },

]