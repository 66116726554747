import { useEffect, useState, useRef } from "react";
import "../../../styles/style.css";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faChevronUp } from "@fortawesome/free-solid-svg-icons";
import DeletButton from "../../addOns/deletButton";

function CreditLine(props) {
    const [designation, setDesignation] = useState(props?.designation || props?.name);
    const [quantity, setQuantity] = useState(parseFloat(props?.quantity).toFixed(2));
    const [unitPrice, setUnitPrice] = useState(parseFloat(props?.unitPrice).toFixed(2));
    const [totalHT, setTotalHT] = useState((props.line.unitPrice * props.line.quantity) || 0);

    const firstRender = useRef(true);

    // Fonction pour mettre à jour la TVA
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const changeAllTva = (allTva) => {
        let newTva = parseInt(allTva);
        var newLine = { ...props.line, vatId: newTva };
        props.updateGroup(newLine);
    };

    useEffect(() => {
        if (firstRender.current) {
            firstRender.current = false;
            return;
        }

        const newTotalHT = (parseFloat(unitPrice || 0) * parseFloat(quantity || 0)).toFixed(2);
        if (newTotalHT !== totalHT) {
            setTotalHT(newTotalHT);
            let newLine = { ...props.line, totalHT: newTotalHT };
            props.updateGroup(newLine);
        }

        if (props.allTva !== undefined) {
            changeAllTva(props.allTva);
        }
    }, [changeAllTva, designation, props, props.allTva, props?.designation, props?.quantity, props?.unitPrice, quantity, totalHT, unitPrice]);

    const onChange = (e) => {
        let value = e.target.value;
        switch (e.target.name) {
            case 'quantity':
                console.log("quantity", value);
                setQuantity(value);
                props.line.quantity = value;
                let newLineQuantity = { ...props.line, quantity: value };
                props.updateGroup(newLineQuantity);
                break;
            case 'unitPrice':
                setUnitPrice(value);
                props.line.unitPrice = value;
                let newLineUnitPrice = { ...props.line, unitPrice: value };
                props.updateGroup(newLineUnitPrice);
                break;
            case 'tva':
                let newTva = parseInt(e.target.value);
                props.line.vatId = newTva;
                let newLineTva = { ...props.line, vatId: newTva };
                props.updateGroup(newLineTva);
                break;
            case 'unit':
                let newUnit = parseInt(e.target.value);
                props.line.unitId = newUnit;
                let newLineUnit = { ...props.line, unitId: newUnit };
                props.updateGroup(newLineUnit);
                break;
            default:
                break;
        }
    };

    const formatInput = (e) => {
        console.log("formatInput", e.target.name);
    let value = e.target.value.replace(',', '.');
    let newLine = { ...props.line };
        console.log(newLine);

    switch (e.target.name) {
        case 'quantity':
            let newQuantity = parseFloat(value).toFixed(2);
            if (isNaN(newQuantity)) newQuantity = 1.00;
            setQuantity(newQuantity);
            newLine.quantity = newQuantity;
            break;
        case 'unitPrice':
            let newUnitPrice = parseFloat(value).toFixed(2);
            if (isNaN(newUnitPrice)) newUnitPrice = 0.00;
            setUnitPrice(newUnitPrice);
            newLine.unitPrice = newUnitPrice;
            break;
        case 'designation':
            setDesignation(value);
            newLine.name = value;
            break;
        default:
            break;
    }

    let newTotalHT = (parseFloat(quantity || 0) * parseFloat(unitPrice || 0.00)).toFixed(2);
    setTotalHT(newTotalHT);
    newLine.totalHT = newTotalHT;
    console.log("newline",newLine);
    props.updateGroup(newLine);
    };

    const validPopContent = {
        title: "Confirmer la suppression",
        desc: `Êtes-vous sûr de vouloir supprimer cette ligne ?`,
    }

    return (
        <div className="invoiceLine" id={props.line.numero}>
            <input type="hidden" name={`idLine${props.grpId}_${props.id}`}/>

            <div className="invoiceLine__leftContent">
                <div className="invoiceLine__leftContent_header">
                    <div className="invoiceLine__leftContent_header_leftPart">
                        <p className="invoiceLine__leftContent_header_leftPart_number">{props.line.numero}</p>
                        <button className="invoiceLine__leftContent_header_leftPart_cart" onClick={() => {
                            props.openCatalogues(props.index, props.grpIndex)
                        }}>
                            <img src={require("../../../assets/images/icons/Picto/Achat.svg").default} alt=""/>
                            <p style={{marginLeft: "3px"}}>Ajouter un article</p>
                        </button>
                        <DeletButton
                            id={props.line.id}
                            className={"invoiceLine__leftContent_header_leftPart_deleteButton"}
                            validPopContent={validPopContent}
                            deleteItemByNotId={props.removeItem}
                        />
                    </div>

                    <div className="invoiceLine__leftContent_header_arrowContainer">
                        <FontAwesomeIcon
                            onClick={() => props.changeOrderLine(props.index, "up")}
                            style={props.line.numero <= 1 ? {opacity: 0.3, cursor: 'not-allowed'} : {}}
                            className='invoiceLine__leftContent_header_arrowContainer_arrow'
                            icon={faChevronUp}
                        />
                        <FontAwesomeIcon
                            onClick={() => props.changeOrderLine(props.index, "down")}
                            style={props.line.numero === props.linesLength.toString() ? {
                                opacity: 0.3,
                                cursor: 'not-allowed'
                            } : {}}
                            className='invoiceLine__leftContent_header_arrowContainer_arrow'
                            icon={faChevronDown}
                        />
                    </div>
                </div>

                <textarea
                    value={props.designation || ""}
                    className="invoiceLine__leftContent_textarea"
                    name="designation"
                    placeholder="Désignation"
                    title="Désignation"
                    onChange={(e) => formatInput(e)}
                />
            </div>

            <div className="invoiceLine__rightContent">
                <div className="invoiceLine__rightContent_inputContainer">
                    <label className="invoiceLine__rightContent_inputContainer_label">Unité</label>
                    <select
                        name="unit"
                        value={props.line.unitId}
                        onChange={(e) => onChange(e)}
                        onBlur={(e) => formatInput(e)}
                        className="invoiceLine__rightContent_inputContainer_input"
                    >
                        {props.listUnit.map((unit, i) => (
                            <option key={i} value={unit.id}>
                                {unit.unit_name}
                            </option>
                        ))}
                    </select>
                </div>

                <div className="invoiceLine__rightContent_inputContainer">
                    <label className="invoiceLine__rightContent_inputContainer_label">Quantité</label>
                    <input
                        type="number"
                        placeholder="Quantité"
                        name="quantity"
                        title="Quantité"
                        value={quantity}
                        onChange={(e) => onChange(e)}
                        onBlur={(e) => formatInput(e)}
                        className="invoiceLine__rightContent_inputContainer_input"
                    />
                </div>

                <div className="invoiceLine__rightContent_inputContainer">
                    <label className="invoiceLine__rightContent_inputContainer_label">Prix
                        HT</label>
                    <input
                        type="number"
                        placeholder="Prix HT"
                        name="unitPrice"
                        title="Prix HT"
                        value={unitPrice}
                        onChange={(e) => onChange(e)}
                        onBlur={(e) => formatInput(e)}
                        className="invoiceLine__rightContent_inputContainer_input"
                    />
                </div>

                <div className="invoiceLine__rightContent_inputContainer">
                    <label className="invoiceLine__rightContent_inputContainer_label">TVA</label>
                    <select
                        name="tva"
                        value={props.tva || ""}
                        onChange={(e) => onChange(e)}
                        className="invoiceLine__rightContent_inputContainer_input"
                    >
                        {props.listTva.map((tva, i) => (
                            <option key={i} value={tva.id}>
                                {tva.rate} %
                            </option>
                        ))}
                    </select>
                </div>

                <h4 className="invoiceLine__rightContent_total">
                    MONTANT HT : <span>{totalHT || 0.00} €</span>
                </h4>
            </div>
        </div>

    );
}

export default CreditLine;
