import Total from "../invoice/recap/total";
import TvaList from "../invoice/recap/tvaList";
import { useEffect, useState } from "react";

export default function DeliveryPrice(props) {
    const [listTva, setListTva] = useState();

    useEffect(() => {
        if(Array.isArray(props.listTva)){
            let newListTva = [...props.listTva];
            for (let k = 0; k < newListTva.length; k++) {
                newListTva[k].value = 0;
            }
            props.invoice?.groups.forEach((grp, i) => {
                grp.lines.forEach((line, j) => {
                    for (let k = 0; k < newListTva.length; k++) {
                        if (newListTva[k].id === line.vatId) {
                            let lineTva = (parseFloat(line.quantity) * parseFloat(line.unitPrice)) * newListTva[k].rate / 100;
                            newListTva[k].value = newListTva[k].value + lineTva;
                        }
                    }
                })
            });
            setListTva(newListTva);
        }
    }, [props])

    return (
        <div className="priceRecap">
            <Total title="Total HT" price={parseFloat(props.invoice?.totalHt).toFixed(2)} />
            <TvaList tvaList={listTva} />
            <Total title="Total net TTC" price={parseFloat(props.invoice?.totalTtc).toFixed(2)} />
        </div>
    );
}
