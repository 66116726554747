import { Link } from "react-router-dom";
export default function NavButton({path, activePathName, name, link}) {
    const handleActive = (e) => {
        return path === activePathName ? 'activeNavButton' : '';
    }
    return (
        <>
            <li className="navigation__links_ul_li"><Link className={`navigation__links_ul_li_link ${handleActive((activePathName))}`} to={link}><span className={`navigation__links_ul_li_link_span `}></span>{name}</Link></li>
        </>
    )

}