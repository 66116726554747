import MailPop from '../../components/addOns/MailPop';
import { useParams } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {useCallback, useEffect, useRef} from 'react';
import CreditGroup from '../../components/customer/credits/creditGroup';
import { useState, useContext } from 'react';
import { useNavigate } from "react-router-dom";
import {faArrowLeft, faCopy, faPaperPlane, faPlus, faPrint,} from "@fortawesome/free-solid-svg-icons";
import {GetApiContext} from "../../context/apiCalls/getApiCalls";
import {PostApiContext} from "../../context/apiCalls/postApiCalls";
import EditingMainHeader from "../../components/addOns/headers/EditingMainHeader";
import {useAuthState} from "../../context/authContext";
import {getCurrentDate} from "../../utils/utils";
import ContactInfosPanel from "../../components/customer/editingPages/informations/ContactInfosPanel";
import RecapContainer from "../../components/customer/invoices/invoice/recap/recapContainer";
import CatalogPop from "../../components/addOns/popComponents/CatalogPop";
import {ContactPopContext} from "../../context/ContactPopContext";

function Credit() {
    const contactPopContext = useContext(ContactPopContext);
    let { id } = useParams()

    let navigate = useNavigate();
    const [listTva, setListTva] = useState([]);
    const tvaRef = useRef(listTva);
    const [contact, setContact] = useState();
    const [contacts, setContacts] = useState([]);
    const nameRef = useRef("");
    const [discount, setDiscount] = useState();
    const [discountPrice, setDiscountPrice] = useState(0);
    const [interlocutorsList, setInterlocutorsList] = useState([]);
    const [comment, setComment] = useState();
    const [showCatalogues, setShowCatalogues] = useState(false);
    const [grpIndexSelected, setGrpIndexSelected] = useState();
    const [lineSelected, setLineSelected] = useState();
    const [lineIndexSelected, setLineIndexSelected] = useState();
    const [listUnit, setListUnit] = useState([]);
    const [subTotalHT, setSubTotalHT] = useState(0);
    const [totalHT, setTotalHT] = useState(0);
    const [totalTTC, setTotalTTC] = useState(0);
    const [ref, setRef] = useState();
    const refRef = useRef("");
    const [credit, setCredit] = useState({});
    const [showCalendar, setShowCalendar] = useState(false);
    const dateRef = useRef(getCurrentDate());
    const [showMail, setShowMail] = useState(false);

    const getApiContext = useContext(GetApiContext);
    const postApiContext = useContext(PostApiContext);
    const {roleId} = useAuthState();

    useEffect(() => {
        getApiContext.getCredit(id)
            .then(response => {
                // console.log("response", response);
                // Réattribut les numéros de groupe
                const updatedGroups = response.credit.groups.map((group, index) => ({...group, numero: index + 1}));
                setCredit({...response.credit, groups: updatedGroups})
                setRef(response.credit.ref);
                nameRef.current.value = response.credit.subject || "";
                setListTva(response.listTva);
                setInterlocutorsList(response.listInterlocutors);
                setComment(response.credit.comment);
                dateRef.current = response.credit?.creditDate;
                setListUnit(response.listUnit);
                setDiscount(response.credit.discount);
                setContact(response.credit.contact);
            })
            .catch(error => {
                console.log("err", error);
            })
    }, [id, getApiContext]);

    useEffect(() => {
        console.log(credit.groups)
    }, [credit]);

    function removeGroup(index) {
        let newCredit = { ...credit };
        console.log("old groups", newCredit.groups);
        const filteredGroups = newCredit.groups.filter((grp, i) => {
            return grp.id !== index;
        });
        //Réattribution des numéros de groupe
        filteredGroups.forEach((grp, i) => {
            grp.numero = i + 1;
        });
        console.log("new groups", filteredGroups);
        newCredit.groups = filteredGroups;
        console.log("new credit", newCredit);
        setCredit(newCredit);
        //(updateCredit(null, null, null, newCredit);
    }

    const changeOrderGroup = (index, direction) => {
        index = parseInt(index + 1);
        let newCredit = { ...credit };
        let creditGroups = newCredit.groups;
        if (direction === "up" && index > 1) {
            const groupTargetIndex = creditGroups.findIndex(group => parseInt(group.numero) === index);
            const groupBeforeIndex = creditGroups.findIndex(group => parseInt(group.numero) === (index - 1));
            if (groupTargetIndex !== -1 && groupBeforeIndex !== -1) {
                const newGroups = [...creditGroups];
                newGroups[groupBeforeIndex].numero = index;
                newGroups[groupTargetIndex].numero = index - 1;
            }
        }
        if (direction === "down" && index < creditGroups.length) {
            const groupTargetIndex = creditGroups.findIndex(group => parseInt(group.numero) === index);
            const groupAfterIndex = creditGroups.findIndex(group => parseInt(group.numero) === (index + 1));

            if (groupTargetIndex !== -1 && groupAfterIndex !== -1) {
                const newGroups = [...creditGroups];
                newGroups[groupAfterIndex].numero = index;
                newGroups[groupTargetIndex].numero = index + 1;
            }
        }
        newCredit.groups = creditGroups;
        setCredit(newCredit);
    }

    function openCatalogues(index, grpIndex) {
        setShowCatalogues(true);
        setGrpIndexSelected(grpIndex);
        setLineIndexSelected(index);
        setLineSelected(credit.groups[grpIndex].lines[index]);
    }

    const updateCredit = useCallback((updatedGroup = undefined, newDiscount = undefined, newDepositPercent = undefined,
        updatedCredit = undefined, estimatedHours = undefined, hourlyCosts = undefined, purchaseCosts = undefined,
        fixedCosts = undefined, variableCosts = undefined) => {
        setCredit(credit => {
            let newCredit;
            if (updatedCredit !== undefined) {
                newCredit = { ...updatedCredit };
            } else {
                newCredit = { ...credit };
            }

            newCredit.groups.forEach((grp) => {
                let groupTotalHT = 0;
                grp?.lines.forEach((line) => {
                    groupTotalHT = parseFloat(groupTotalHT) + (parseFloat(line.quantity) * parseFloat(line.unitPrice)).toFixed(2);
                });
                if (grp){grp.totalHT = groupTotalHT;}

            });

            const newGroup = [...newCredit.groups];
            if (updatedGroup !== undefined && updatedGroup !== null) {
                newGroup[updatedGroup.numero - 1] = updatedGroup;
                newCredit = { ...newCredit, groups: newGroup };
            }
            let subtotalHT = 0;
            newGroup.forEach((grp) => {
                subtotalHT += parseFloat(grp?.totalHT || "0");
            });
            setSubTotalHT(subtotalHT);
            let newdiscount = 0;
            let newdiscountPrice;
            if (newDiscount !== undefined) {
                newdiscount = newDiscount;
                setDiscount(newDiscount);
                newdiscountPrice = subtotalHT * (parseFloat(newDiscount) / 100);
            } else {
                newdiscount = discount;
                if (newdiscount !== undefined) {
                    newdiscountPrice = subtotalHT * (newdiscount / 100);
                } else {
                    newdiscountPrice = 0;
                }
            }
            setDiscountPrice(newdiscountPrice || 0.00);
            let totalht = subtotalHT.toFixed(2) - (newdiscountPrice || 0).toFixed(2);
            setTotalHT(totalht);

            let newListTva = [...listTva];

            for (let k = 0; k < newListTva.length; k++) {
                newListTva[k].value = 0;
            }

            // console.log("newCredit", newCredit);
            newCredit.groups.forEach((grp) => {
                grp?.lines.forEach((line) => {
                    for (let k = 0; k < newListTva.length; k++) {
                        if (parseInt(newListTva[k].id) === parseInt(line.vatId)) {
                            let lineTva = ((parseFloat(line.quantity) * parseFloat(line.unitPrice)) - ((parseFloat(line.quantity) * parseFloat(line.unitPrice)) * (newdiscount / 100))) * newListTva[k].rate / 100;
                            // console.log("line", line.name, ": " , lineTva, "€ de tva à ", newListTva[k].rate, "%");
                            newListTva[k].value = newListTva[k].value + lineTva;
                        }
                    }
                })
            });
            // console.log("newListTva", newListTva);
            tvaRef.current = newListTva;
            setListTva(newListTva);

            let amountTotalTva = 0;
            for (let k = 0; k < newListTva.length; k++) {
                amountTotalTva += newListTva[k].value;
            }
            let totalttc = parseFloat(totalht.toFixed(2)) + parseFloat(amountTotalTva.toFixed(2));
            setTotalTTC(totalttc);

            if (estimatedHours !== undefined) {
                newCredit = { ...newCredit, estimatedHours: estimatedHours };
            }
            if (hourlyCosts !== undefined) {
                newCredit = { ...newCredit, averageHourlyRateHT: hourlyCosts };
            }
            if (purchaseCosts !== undefined) {
                newCredit = { ...newCredit, purchaseCostsHT: purchaseCosts };
            }
            if (fixedCosts !== undefined) {
                newCredit = { ...newCredit, fixedCosts: fixedCosts };
            }
            if (variableCosts !== undefined) {
                newCredit = { ...newCredit, variableCosts: variableCosts };
            }
            return newCredit;
        });
    }, [discount, listTva]);

    /*const printCredit = () => {
        window.open(`${process.env.REACT_APP_CREDIT_PRINT}${credit.id}`, "_blank");
    }*/

    const checkAndUpdateLines = useCallback((lines) => {
        lines?.forEach((line, index) => {
            if (line.numero !== index) {
                // Si le numero est incorrect, on utilise updateOrder pour corriger la ligne
                updateCredit({
                    ...line,
                    numero: index,  // Nouveau numéro correct
                });
            }
        });
    }, [updateCredit]);

    useEffect(() => {
        checkAndUpdateLines(credit?.lines);
    }, [checkAndUpdateLines, credit]);

    const onChangeInterlocutor = (e) => {
        let newInterlocutor = e.target.value;
        let newCredit = { ...credit, userId: newInterlocutor };
        setCredit(newCredit);
    }

    function duplicate() {
        onValidCredit(null, "");
        getApiContext.getCredit("", credit.id).then(response => {
            console.log(response)
            let newCredit = response.credit;
            navigate("/creditList/credit/" + newCredit.id);
        })
    }

    const onValidCredit = async (e, path = "/creditList") => {
        e?.preventDefault();

        const values = {
            ref: ref,
            destinataire: contact?.id,
            date_emission: dateRef?.current || getCurrentDate(),
            titre: nameRef?.current?.value || "",
            interlocuteur: credit.userId,
            Hremise1: discount,
            comment: comment,
            heuresTotFact: credit?.estimatedHours,
            tauxHoraireMoyen: credit?.averageHourlyRateHT,
            coutsAchat: credit?.purchaseCostsHT,
            fraisFixes: credit?.fixedCosts,
            fraisVariables: credit?.variableCosts,
            Htotalht1: totalHT,
            Htotalttc: totalTTC,
            groups: credit?.groups,
        };
        console.log("log de value ", values);
        postApiContext.postCredit(id, values)
            .then(response => {
                console.log("log du response de post ", response);
                navigate(path);
            })
            .catch(error => {
                console.log(error);
            })
    }
    const handleClickModal = () => {
        if (contactPopContext.showContactPopup === false) {
            getApiContext.getContactsList()
                .then(response => {
                    console.log("response", response);
                    setContacts(response.listContacts);
                });
        }
        contactPopContext.setShowContactPopup(true);
    }

    function handleSelectedContact(index) {
        contacts.forEach(element => {
            if (element.id === index) {
                setContact(element);
            }
        });
        contactPopContext.setShowContactPopup(false)

    }

    const addGrp = () => {
        console.log("addGrp");
        let newCredit = { ...credit }
        let id = generateId();
        newCredit.groups.push(
            {
                id: id,
                creditId: credit.id,
                title: "",
                totalHT: 0,
                numero: newCredit.groups.length + 1 || 0,
                lines: [
                    {
                        id: "New0",
                        designation: "",
                        unit: "",
                        quantity: "1.00",
                        unitPrice: "0.00",
                        tva: 8,
                        totalHT: "0.00",
                        unitId: 1,
                        numero: 1,
                        vatId: 8,
                    },
                ]
            });
        setCredit(newCredit);
        //updateCredit();
    }

    const generateId = () => {
        let prefixIdLine = "New";
        let newIdLig = 0;

        credit.groups.forEach((grp) => {
            while (grp.id === prefixIdLine + newIdLig) {
                newIdLig += 1;
            }
        });
        return prefixIdLine + newIdLig;
    }
    const onChangeInput = (e) => {
        console.log("yes", e.target.value)
        let value = e.target.value;
        const validated = value.match(/^(\d*\.{0,1}\d{0,2}$)/);
        if (e.target.name === "discount") {
            console.log("discount", value);
            if (validated) {
                setDiscount(value);
                credit.discount = value;
            } else {
                value = 0;
                setDiscount(value);
            }
            updateCredit(null, value, null);
        } else if (e.target.name === "deposit") {
            if (!validated) {
                value = 0
            }
            updateCredit(null, null, value);
        }
    }
    /*const changeAllTva = (i) => {
        switch (i) {
            case 1:
                setAllTva(3);
                break;
            case 2:
                setAllTva(5);
                break;
            case 3:
                setAllTva(2);
                break;
            case 4:
                setAllTva(1);
                break;
            default:
                setAllTva(8)
                break;
        }
    }*/
    const formatInput = (e) => {
        console.log(e.target)
        if (e.target.name === "discount") {
            var newDiscount = e.target.value;
            newDiscount = parseFloat(parseFloat(newDiscount.replace(',', '.')).toFixed(2));
            if (isNaN(newDiscount)) {
                newDiscount = "0.00";
            } else {
                newDiscount = parseFloat(newDiscount).toFixed(2);
            }
            setDiscount(newDiscount);
        }
    }

    function handleSelectedArticle(article) {
        let item = article;
        setShowCatalogues(false);
        setCredit(credit => {
            let newCredit = { ...credit };
            let newLine = { ...lineSelected };
            newLine.name = item.name;
            newLine.quantity = 1;
            newLine.unitPrice = item.basePrice;
            newCredit.groups[grpIndexSelected].lines[lineIndexSelected] = newLine;
            console.log("lineSelected", newLine);
            console.log(newCredit);
            return newCredit;
        });
    }

    function closeMailPop() {
        setShowMail(false);
    }

    const headerButtonsList={
        dropdown: {
            imprimer:{content: "Imprimer", handleClick: () => console.log("hello"),
                icon: faPrint, redir: false },
            envoyer:{ content: "Envoyer", handleClick: () => setShowMail(true),
                icon: faPaperPlane, redir: false },
            dupliquer:{ content: "Dupliquer", handleClick: () => duplicate(),
                icon: faCopy, redir: false },
        },
        mainButton: {
            buttonIcon: faArrowLeft,
            buttonText: id ? "Sauvegarder" : "Créer l'avoir",
            buttonAction: (e) => onValidCredit(e),
        },
    }

    return (
        <>
            {showCatalogues && <CatalogPop setShowCatalogues={setShowCatalogues} handleSelectedArticle={handleSelectedArticle}/>}
                <MailPop
                    show={showMail}
                    creditId={credit.id}
                    closeMailPop={closeMailPop}
                />
            <EditingMainHeader roleId={roleId}
                               headerTitle="AVOIR" dateRef={dateRef} setShowCalendar={setShowCalendar} showCalendar={showCalendar}
                               destination={"/creditList"} endPoint={"c-deactive-credit"}
                               id={id} refRef={refRef} defaultRef={ref}
                               headerButtonsList={headerButtonsList}
                               backRedirect="/quotesList"
            />
                    <div className="contract listPage">
                        <div className="block">
                            <div className="editingInfos">
                                <div className="editingInfos__titleContainer">
                                    <h2 className="editingInfos__titleContainer_title">informations</h2>
                                    <hr className="editingInfos__titleContainer_line"/>
                                </div>
                                <div className='editingInfos__firstSection'>
                                    <div className="editingInfos__firstSection_nameContainer">
                                        <h3 className="editingInfos__firstSection_nameContainer_title">Intitulé de l'avoir :</h3>
                                        <input className="editingInfos__firstSection_nameContainer_input" type="text"
                                               ref={nameRef}
                                               placeholder="EXEMPLE DE DEPENSE"/>
                                    </div>
                                    <div className="editingInfos__firstSection_interContainer">
                                        <h3 className="editingInfos__firstSection_interContainer_title">Affaire suivie
                                            par :</h3>
                                        <select onChange={onChangeInterlocutor} value={credit?.userId}
                                                className="editingInfos__firstSection_interContainer_select"
                                                name="interlocutorSelect"
                                                id="interlocutorSelect">
                                            {
                                                interlocutorsList && interlocutorsList.length > 0 &&
                                                interlocutorsList.map((interlocutor, index) => {
                                                    return (
                                                        <option
                                                            className="editingInfos__firstSection_interContainer_option"
                                                            key={index}
                                                            value={interlocutor.id}>{interlocutor.first_name} {interlocutor.last_name}</option>
                                                    )
                                                })
                                            }
                                        </select>
                                    </div>
                                </div>
                                <ContactInfosPanel contact={contact} handleClickModal={handleClickModal} onSelectContact={handleSelectedContact} setContacts={setContacts}/>
                            </div>
                            <div className="contract-body quoteGroup">
                                <div className="quoteGroup__titleContainer">
                                    <h2 className="quoteGroup__titleContainer_title">Description du produit</h2>
                                    <hr className='quoteGroup__titleContainer_line'/>
                                </div>
                                <div className="container">
                                    {
                                        credit?.groups
                                            ?.sort((a, b) => a.numero - b.numero)
                                            .map((group, i) => {
                                                return (
                                                    <CreditGroup
                                                        key={group?.id}
                                                        index={i}
                                                        group={group}
                                                        id={group?.id}
                                                        numero={i}
                                                        designation={(group?.title === undefined) ? "" : group.title}
                                                        lines={group?.lines}
                                                        listTva={listTva}
                                                        listUnit={listUnit}
                                                        creditId={credit.id}
                                                        updateCredit={updateCredit}
                                                        openCatalogues={openCatalogues}
                                                        removeGroup={removeGroup}
                                                        changeOrderGroup={changeOrderGroup}
                                                        credit={credit}
                                                    />
                                                )
                                            })
                                    }
                                    <div className="new-group" onClick={addGrp}>
                                        <p>Ajouter un groupe d'articles à l'avoir</p>
                                        <FontAwesomeIcon icon={faPlus}/>
                                    </div>
                                    <div className="comment">
                                        <h3>COMMENTAIRE AJOUTE A L'AVOIR</h3>
                                        <textarea placeholder="Description" value={comment || ""}
                                                  onChange={(e) => setComment(e.target.value)}></textarea>
                                    </div>

                                </div>
                            </div>
                            <div className="aside">
                                <RecapContainer invoiceId={credit?.id} totalTtc={totalTTC} totalHt={totalHT} totalInvoice={subTotalHT}
                                                discount={discount} discountPrice={parseFloat(discountPrice).toFixed(2)}
                                                handleDiscountChange={onChangeInput} handleDiscountBlur={formatInput}
                                                vatList={tvaRef.current}/>
                            </div>
                        </div>
                    </div>
        </>
    )


}

export default Credit