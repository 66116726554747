import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {useCallback, useEffect, useState, useContext, useRef} from "react";
import { useNavigate, useParams } from "react-router-dom";
import MailPop from "../../components/addOns/MailPop";
import OrderLine from "../../components/customer/order/OrderLine";
import { AppContext } from "../../context/appContext";
import {
    faArrowLeft,
    faCopy, faMoneyCheckDollar, faPaperPlane,
    faPlus, faPrint,
    faShuffle,
} from "@fortawesome/free-solid-svg-icons";
import EditingMainHeader from "../../components/addOns/headers/EditingMainHeader";
import {useAuthState} from "../../context/authContext";
import ContactInfosPanel from "../../components/customer/editingPages/informations/ContactInfosPanel";
import {GetApiContext} from "../../context/apiCalls/getApiCalls";
import {PostApiContext} from "../../context/apiCalls/postApiCalls";
import Calendar from "react-calendar";
import {getCurrentDate} from "../../utils/utils";
import CatalogPop from "../../components/addOns/popComponents/CatalogPop";
import {ContactPopContext} from "../../context/ContactPopContext";

function Order() {

    let { id } = useParams();
    let navigate = useNavigate();
    const appContext = useContext(AppContext);
    const roleId = useAuthState();

    const nameRef = useRef();
    const ref = useRef();

    const [order, setOrder] = useState({});
    const [listTva, setListTva] = useState([]);
    const [listUnit, setListUnit] = useState([]);
    const [interlocutorsList, setInterlocutorsList] = useState();
    const [interlocutor, setInterlocutor] = useState("")
    const dateRef = useRef();
    const [dateValidation, setDateValidation] = useState(new Date());
    const [subTotalHT, setSubTotalHT] = useState(0);
    const [totalHT, setTotalHT] = useState(0);
    const [totalTTC, setTotalTTC] = useState(0);
    const [contact, setContact] = useState();
    const [discount, setDiscount] = useState();
    const [discountPrice, setDiscountPrice] = useState();
    const [comment, setComment] = useState();
    const [showMail, setShowMail] = useState(false);
    const [contacts, setContacts] = useState([]);
    const [showCatalogues, setShowCatalogues] = useState(false);
    const [lineSelected, setLineSelected] = useState();
    const [lineIndexSelected, setLineIndexSelected] = useState();
    const [calendarPop, setCalendarPop] = useState(false);

    const getApiContext = useContext(GetApiContext);
    const postApiContext = useContext(PostApiContext);
    const contactPopContext = useContext(ContactPopContext);

    useEffect(() => {
        getApiContext.getOrder(id)
            .then(response => {
                console.log(response);
                setOrder(response.order);
                setListTva(response.listTva);
                setListUnit(response.listUnit);
                setInterlocutorsList(response.listInterlocutors);
                setInterlocutor(response.order?.user);
                ref.current.value = response.order?.ref;
                setDiscount(response.order?.discount);
                nameRef.current.value = response.order?.subject;
                setTotalHT(response.order?.totalHT);
                setTotalTTC(response.order?.totalTTC);
                setContact(response.order?.contact)
                setComment(response.order?.comment);
                dateRef.current = response.order?.orderDate;
                if (response.order?.paymentDate !== null) {
                    let dayValidation = response.order?.paymentDate.split("-")[1];
                    let monthValidation = response.order?.paymentDate.split("-")[2];
                    let yearValidation = response.order?.paymentDate.split("-")[0];
                    let newDateValidation = dayValidation + "/" + monthValidation + "/" + yearValidation;
                    setDateValidation(new Date(newDateValidation));
                } else {
                    setDateValidation(null);
                }
                
            })
            .catch(error => {
                console.log("err GET order.js", error);
            })

    },[id, getApiContext]);

    const headerButtonsList={
        dropdown: {
            imprimer:{content: "Imprimer", handleClick: () => console.log("hello"),
                icon: faPrint, redir: false },
            envoyer:{ content: "Envoyer", handleClick: () => setShowMail(true),
                icon: faPaperPlane, redir: false },
            dupliquer:{ content: "Dupliquer", handleClick: () => duplicate(),
                icon: faCopy, redir: false },
            transferer:{ content: "Transférer", handleClick: () => console.log("hello"),
                icon: faShuffle, redir: false },
        },
        mainButton: {
            buttonIcon: faArrowLeft,
            buttonText: id ? "Sauvegarder" : "Ajouter la dépense",
            buttonAction: (e) => onValidOrder(e, "/ordersList"),
        },
    }


    const onValidOrder = async (e, path) => {
        e?.preventDefault();

        const values = {
            reference: ref?.current?.value,
            destinataire: contact?.id,
            date_emission: dateRef?.current || getCurrentDate(),
            date_validation: dateValidation?.toLocaleDateString(),
            subject: nameRef?.current?.value,
            interlocuteur: order.userId,
            Hremise1: discount,
            commentaire: comment,
            Htotalht: totalHT,
            Htotalttc: totalTTC,
            lines: order.lines,
        };

        console.log("post values",values);

        postApiContext.postOrder(id, values)
            .then(response => {
                //console.log('order response', response);
                navigate(path);
            })
            .catch(error => {
                console.log('values err', values);
                console.log(error);
            })
    }


    const handleClickCalendarValidation = () => {
        setCalendarPop(true);
    }

    const updateOrder = useCallback((updateLine = undefined, newDiscount = undefined, updateOrder = undefined) => {
        setOrder(order => {
            let newOrder = updateOrder !== undefined && updateOrder !== null ? { ...updateOrder } : { ...order };

            // Créer une copie des lignes
            let newLines = [...newOrder.lines];
            if (updateLine !== undefined && updateLine !== null) {
                newLines[updateLine.numero - 1] = updateLine;
                newOrder = { ...newOrder, lines: newLines };
            }

            // Calcul du sous-total HT
            let subtotalHT = 0;
            newLines?.forEach(line => {
                subtotalHT += (parseFloat(line.quantity * line.unitPrice) || 0);
            });
            setSubTotalHT(subtotalHT);

            // Gestion de la remise (discount)
            let newdiscount = newDiscount !== undefined ? newDiscount : discount;
            setDiscount(newdiscount);

            let discountPrice = (subtotalHT * (parseFloat(newdiscount || '0.00') / 100));
            setDiscountPrice(discountPrice);

            let totalht = subtotalHT - discountPrice;
            setTotalHT(totalht || 0.00);

            let newListTva = [...listTva].map(tva => ({ ...tva, value: 0 }));

            // Mise à jour des valeurs de TVA pour chaque ligne
            newOrder?.lines?.forEach(line => {
                for (let j = 0; j < newListTva.length; j++) {
                    if (String(newListTva[j].id) === String(line.vatId)) {
                        let lineTva = ((parseFloat(line.quantity) * parseFloat(line.unitPrice)) - ((parseFloat(line.quantity) * parseFloat(line.unitPrice)) * (newdiscount / 100))) * newListTva[j].rate / 100;
                        newListTva[j].value += lineTva;
                    }
                }
            });

            setListTva(newListTva);

            // Calcul du total TVA
            let amountTotalTva = newListTva.reduce((acc, tva) => acc + tva.value, 0);

            // Calcul du total TTC
            let totalttc = totalht + amountTotalTva;
            setTotalTTC(totalttc);

            return newOrder;
        });
    }, [listTva, discount]);


    const checkAndUpdateLines = useCallback((lines) => {
        lines?.forEach((line, index) => {
            if (line.numero !== index + 1) {
                // Si le numero est incorrect, on utilise updateOrder pour corriger la ligne
                updateOrder({
                    ...line,
                    numero: index + 1,  // Nouveau numéro correct
                });
            }
        });
    }, [updateOrder]);

    useEffect(() => {
        checkAndUpdateLines(order?.lines);
    }, [checkAndUpdateLines, order]);

    const onChangeInput = (e) => {
        let value = e.target.value;
        const validated = value.match(/^(\d*\.{0,1}\d{0,2}$)/);
        if (e.target.name === "discount") {
            if (validated) {
                setDiscount(value);
            } else {
                value = 0;
                setDiscount(value);
            }
            updateOrder(null, value, null);
        }
    }

    const formatInput = (e) => {
        if (e.target.name === "discount") {
            let newDiscount = e.target.value;
            newDiscount = parseFloat(parseFloat(newDiscount.replace(',', '.')).toFixed(2));
            if (isNaN(newDiscount)) {
                newDiscount = "0.00";
            } else {
                newDiscount = parseFloat(newDiscount).toFixed(2);
            }
            setDiscount(newDiscount);
        }
        updateOrder();
    }

    const handleClickModal = () => {
        if (contactPopContext.showContactPopup === false) {
            getApiContext.getContactsList()
                .then(response => {
                    setContacts(response.listContacts);
                });
        }
        contactPopContext.setShowContactPopup(true);
    }

    function handleSelectedContact(index) {
        console.log(index);
        contacts.forEach((element) => {
            if (element.id === index) {
                setContact(element);
            }
        });
        contactPopContext.setShowContactPopup(false);
    }

    function openCatalogues(index) {
        setShowCatalogues(true);
        setLineIndexSelected(index);
        setLineSelected(order.lines[index]);
    }

    function handleSelectedArticle(article) {
        let item = article;
        setShowCatalogues(false);
        setOrder(order => {
            let newOrder = { ...order };
            let newLine = { ...lineSelected };
            newLine.name = item.name;
            newLine.quantity = 1;
            newLine.unitPrice = item.basePrice;
            newOrder.lines[lineIndexSelected] = newLine;
            return newOrder;
        });
    }

    const filterDate = (date) => {
        if (date){
            const year = date.getFullYear()
            const month = date.getMonth() + 1
            const day = date.getDate()
            return `${year}-${month}-${day}`
        }
    }

    const onChangeInterlocutor = (e) => {
        let newInterlocutors = e.target.value;
        let newOrder = { ...order, userId: newInterlocutors };
        setInterlocutor(newInterlocutors);
        setOrder(newOrder);
    }

    const addLine = () => {
        let newOrder = { ...order };
        let id = generateId();
        newOrder && newOrder?.lines && newOrder?.lines?.push(
            {
                id: id,
                name: "",
                unit: "",
                quantity: "1.00",
                unitPrice: "0.00",
                totalHT: "0.00",
                vatId: 8,
                unitId: 1
            }
        );
        setOrder(newOrder);
        updateOrder();
    }

    function removeItem(index) {
        let newOrder = { ...order };
        const filteredLines = order.lines.filter((line, i) => {
            return i !== index;
        });
        newOrder.lines = filteredLines;
        setOrder(newOrder);
        updateOrder();
    }

    function changeOrderLine(index, direction) {
        index = parseInt(index + 1);
        let newOrder = { ...order };
        let lines = newOrder.lines;
        if (direction === "up" && index > 1) {
            const lineTargetIndex = lines.findIndex(line => parseInt(line.numero) === index);
            const lineBeforeIndex = lines.findIndex(line => parseInt(line.numero) === (index - 1));
            console.log(lineTargetIndex, lineBeforeIndex);
            if (lineTargetIndex !== -1 && lineBeforeIndex !== -1) {
                const newLines = [...lines];
                newLines[lineBeforeIndex].numero = index;
                newLines[lineTargetIndex].numero = (index - 1);
            }
        } else if (direction === "down" && index < lines.length) {
            const lineTargetIndex = lines.findIndex(line => parseInt(line.numero) === index);
            const nextIndex = (index + 1);
            const lineAfterIndex = lines.findIndex(line => parseInt(line.numero) === nextIndex);
            console.log(lineTargetIndex, lineAfterIndex);
            if (lineTargetIndex !== -1 && lineAfterIndex !== -1) {
                const newLines = [...lines];
                newLines[lineAfterIndex].numero = index;
                newLines[lineTargetIndex].numero = nextIndex;
            }
        }
        console.log("nexOrder", newOrder.lines)
        setOrder(newOrder);
        updateOrder(null, null, newOrder);
    }

    const generateId = () => {
        let prefixIdLine = "New";
        let newIdLig = 0;

        // eslint-disable-next-line array-callback-return
        order && order?.lines.map((line) => {
            while (line.id === prefixIdLine + newIdLig) {
                newIdLig += 1;
            }
        });
        return prefixIdLine + newIdLig;
    }

    /*const printOrder = () => {
        window.open(`${process.env.REACT_APP_ORDER_PRINT}${order.id}`, "_blank");
    }*/

    function closeMailPop() {
        appContext.setShowMailPop(false);
    }

    function duplicate() {
        onValidOrder(null, "");
        getApiContext.getOrder(null, id).then(response => {
            console.log(response)
            let newOrder = response.order;
            navigate("/ordersList/order/" + newOrder.id);
        })
    }

    const handleChangeDate = (date) => {
        setDateValidation(date);
        setCalendarPop(false);
    }

    useEffect(() => {
        if (showCatalogues) {
            document.body.style.overflow = 'hidden';
        } else {
            document.body.style.overflow = 'auto';
        }

        // Cleanup on unmount
        return () => {
            document.body.style.overflow = 'auto';
        };
    }, [showCatalogues]);

    return (
        <>
            {calendarPop &&
                <div onClick={() => setCalendarPop(false)} className="grayBlur"></div>
            }
            {calendarPop &&
                <>
                    <Calendar onChange={handleChangeDate} />
                </>}
            {showCatalogues &&
                <CatalogPop setShowCatalogues={setShowCatalogues} handleSelectedArticle={handleSelectedArticle} />
            }

            {showMail &&
                <MailPop
                    orderId={order?.id}
                    closeMailPop={closeMailPop}
                />}
            <EditingMainHeader headerTitle={'DÉPENSES'}
                               headerButtonsList={headerButtonsList}
                               backRedirect={"/ordersList"}
                               roleId={roleId}
                               refRef={ref}
                               endPoint={"c-deactive-order"}
                               id={id}
                               destination={"/ordersList"}
                               dateRef={dateRef}
            />
                    <div className="contract listPage">
                        <div className="block">
                            <div className="editingInfos">
                                <div className="editingInfos__titleContainer">
                                    <h2 className="editingInfos__titleContainer_title">informations</h2>
                                    <hr className="editingInfos__titleContainer_line"/>
                                </div>
                                <div className='editingInfos__firstSection'>
                                    <div className="editingInfos__firstSection_nameContainer">
                                        <h3 className="editingInfos__firstSection_nameContainer_title">Intitulé de la
                                            dépense :</h3>
                                        <input className="editingInfos__firstSection_nameContainer_input" type="text"
                                               defaultValue={""}
                                               ref={nameRef}
                                               placeholder="EXEMPLE DE DEPENSE"/>
                                    </div>
                                    <div className="editingInfos__firstSection_interContainer">
                                        <h3 className="editingInfos__firstSection_interContainer_title">Affaire suivie
                                            par :</h3>
                                        <select onChange={onChangeInterlocutor} value={interlocutor?.id}
                                                className="editingInfos__firstSection_interContainer_select"
                                                name="interlocutorSelect"
                                                id="interlocutorSelect">
                                            {
                                                interlocutorsList && interlocutorsList.length > 0 &&
                                                interlocutorsList.map((interlocutor, index) => {
                                                    return (
                                                        <option
                                                            className="editingInfos__firstSection_interContainer_option"
                                                            key={index}
                                                            value={interlocutor.id}>{interlocutor.first_name} {interlocutor.last_name}</option>
                                                    )
                                                })
                                            }
                                        </select>
                                    </div>
                                </div>
                                <ContactInfosPanel contact={contact} handleClickModal={handleClickModal} setContacts={setContacts} onSelectContact={handleSelectedContact}/>
                            </div>
                            <div className="contract-body">
                                <div className="container">
                                    <div className="group">
                                        {order?.lines
                                            ?.sort((a, b) => a.numero - b.numero)
                                            .map((line, i) => {
                                                return (
                                                    <OrderLine
                                                        key={i}
                                                        index={i}
                                                        numero={i + 1}
                                                        line={line}
                                                        id={line.id}
                                                        designation={line.name}
                                                        listTva={listTva}
                                                        listUnit={listUnit}
                                                        quantity={line.quantity}
                                                        unitPrice={line.unitPrice}
                                                        updateOrder={updateOrder}
                                                        openCatalogues={openCatalogues}
                                                        removeItem={removeItem}
                                                        changeOrderLine={changeOrderLine}
                                                    />
                                                )
                                            })
                                        }

                                    </div>
                                    <div className="new-group" onClick={addLine}>
                                        <p>Ajouter une nouvelle ligne</p>
                                        <FontAwesomeIcon icon={faPlus}/>
                                    </div>
                                </div>
                                <div className="comment">
                                    <h3>COMMENTAIRE AJOUTÉ À LA DÉPENSE</h3>
                                    <textarea placeholder="Description" value={comment || ""}
                                              onChange={(e) => setComment(e.target.value)}></textarea>
                                </div>
                            </div>
                            <div className="aside">
                                <div className="marginPrice">
                                    <div className="marginPrice__bold-block bold-block sub-total-ht">
                                        <p>Sous total HT</p>
                                        <p>{parseFloat(subTotalHT).toFixed(2)} €</p>
                                    </div>
                                            <div className="discount">
                                                <div>
                                                    <p>Remise Globale</p>
                                                    <input type="text" placeholder="0,00" name="discount" value={discount || ""} onChange={onChangeInput} onBlur={formatInput} /> %
                                                </div>
                                                <p>{parseFloat(discountPrice).toFixed(2)} €</p>
                                            </div>
                                            <div className="marginPrice__bold-block bold-block total-ht">
                                                <p>Total HT</p>
                                                <p>{parseFloat(totalHT).toFixed(2)} €</p>
                                            </div>
                                            <div className="marginPrice__tva tva">
                                                {
                                                    listTva &&
                                                    listTva.map((tva, i) => {
                                                        if (tva.id !== 8) {
                                                            return (
                                                                <div key={i}>
                                                                    <p>TVA à {tva.rate}</p>
                                                                    <p>{parseFloat(tva.value).toFixed(2)} €</p>
                                                                </div>
                                                            );
                                                        }
                                                        return null;
                                                    })
                                                }
                                            </div>
                                            <div className="marginPrice__bold-block bold-block total-ttc">
                                                <p>Total net TTC</p>
                                                <p>{parseFloat(totalTTC).toFixed(2)} €</p>
                                            </div>
                                    <div className="payementDate">

                                        <p><FontAwesomeIcon icon={faMoneyCheckDollar}/>Suivi de règlement :</p>
                                        <div>Date de livraison
                                            <p className="calendarLogoBg " onClick={handleClickCalendarValidation}>
                                                <img className="calendarLogo" id="payementDate"
                                                     src="/calendar-days-solid.svg" alt=""/>
                                            </p>

                                            <span>&thinsp;&thinsp;&thinsp;{filterDate(dateValidation)}</span>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
        </>
    )

}

export default Order;