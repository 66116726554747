import { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown, faChevronUp } from "@fortawesome/free-solid-svg-icons";
import DeliveryLine from "./DeliveryLine";
import Decimal from "decimal.js";

export default function DeliveryGroup({ deleteGroupByNotId, listUnit, handleChangeGroup, setGroupLines, vatList, group, setRefresh, deleteLineByNotId, handleMoveGroup }) {
    const [lines, setLines] = useState([]);
    const [totalGroup, setTotalGroup] = useState(0);

    useEffect(() => {
        setLines(group?.lines);
    }, [group]);

    useEffect(() => {
        let total = new Decimal(0);
        lines.forEach(line => {
            const quantityDecimal = new Decimal(line.invoicedQuantity || 0);
            const unitPriceDecimal = new Decimal(line.unitPrice || 0);
            const totalLine = quantityDecimal.times(unitPriceDecimal);
            total = total.plus(totalLine);
        });
        setTotalGroup(total.toNumber());
    }, [lines]);

    const handleMoveLine = (index, direction) => {
        index = parseInt(index);
        if (direction === "up" && index > 1) {
            const lineTargetIndex = lines.findIndex(line => line.numero.toString() === index.toString());
            const lineBeforeIndex = lines.findIndex(line => line.numero === (index - 1).toString());
            if (lineTargetIndex !== -1 && lineBeforeIndex !== -1) {
                const newLines = [...lines];
                newLines[lineBeforeIndex].numero = index.toString();
                newLines[lineTargetIndex].numero = (index - 1).toString();
                setLines(newLines);
            }
        } else if (direction === "down" && index < lines.length) {
            const lineTargetIndex = lines.findIndex(line => line.numero === index.toString());
            const nextIndex = (index + 1).toString();
            const lineAfterIndex = lines.findIndex(line => line.numero === nextIndex);
            if (lineTargetIndex !== -1 && lineAfterIndex !== -1) {
                const newLines = [...lines];
                newLines[lineAfterIndex].numero = index.toString();
                newLines[lineTargetIndex].numero = nextIndex;
                setLines(newLines);
            }
        }
    };

    const handleLineChange = (element, id) => {
        const newLines = [...lines];
        const index = newLines.findIndex(line => line.id === id);
        const name = element.target.name;
        newLines[index][name] = element.target.value;
        setLines(newLines);
        setGroupLines(newLines, group.id);
    };

    const handelGetGroupId = (id) => {
        deleteLineByNotId(id, group.id);
    };

    return (
        <div className='invoiceGroup'>
            <div className="invoiceGroup__groupContainer">
                <div className="invoiceGroup__groupContainer_groupHeader">
                    <div className="invoiceGroup__groupContainer_groupHeader_firstPart">
                        <div className="invoiceGroup__groupContainer_groupHeader_firstPart_arrowGroup">
                            <p onClick={() => { handleMoveGroup(group?.numero, "up") }}>
                                <FontAwesomeIcon
                                    className='invoiceGroup__groupContainer_groupHeader_firstPart_arrowGroup_arrow'
                                    icon={faChevronUp}/>
                            </p>
                            <p onClick={() => { handleMoveGroup(group?.numero, "down") }}>
                                <FontAwesomeIcon
                                    className='invoiceGroup__groupContainer_groupHeader_firstPart_arrowGroup_arrow'
                                    icon={faChevronDown}/>
                            </p>
                        </div>
                        <input name="title" onChange={(e) => handleChangeGroup(e, group.id)} className='invoiceGroup__groupContainer_groupHeader_firstPart_input' type="text"
                               placeholder="Nom du groupe d'articles" value={group?.subject || ""}/>
                    </div>
                    <p className='invoiceGroup__groupContainer_groupHeader_total'>Montant HT : <span>{totalGroup}</span>€
                    </p>
                </div>
                {
                    lines &&
                    lines
                        .slice()
                        .sort((a, b) => parseInt(a.numero) - parseInt(b.numero))
                        .map((line, index) => {
                            return (
                                <DeliveryLine deleteLineByNotId={handelGetGroupId} setRefresh={setRefresh} linesLength={lines?.length} listUnit={listUnit} handleLineChange={handleLineChange} handleMoveLine={handleMoveLine} vatList={vatList} line={line}
                                              key={index} index={index}/>
                            )
                        })
                }
            </div>
        </div>
    );
}
