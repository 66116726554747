import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import "../../../styles/style.css";
import { useNavigate } from 'react-router';
import {faHouse, faPhone} from "@fortawesome/free-solid-svg-icons";

function ContactCard(props) {

    const navigate = useNavigate();

    const openContact = () => {
        if (!props.showModal && !props.openContact) {
            navigate(`/contactsList/contact/${props.contactId}`);
        } else {
            //props.handleSelectedContact(props.contactId);
            props.openContact(props.contactId);
            //props.setShowModal(false);
        }
    }
    const phone = props.mobilePhone
    

    return (

        <section onClick={() => openContact(props.valid)} className="cards" key={props.contactId} id={props.contactId}>

            <div className="tileCard cards__contactContainer">
                <div className="cards__contactContainer_titleContainer">
                    <div className="cards__contactContainer_titleContainer_title">
                        <p className="cards__contactContainer_titleContainer_title_name">{props?.lastName} {props?.firstName} {((props?.lastName || props?.firstName) && props.society) && `- ${ props.function}`}</p>
                        <div className="cards__contactContainer_titleContainer_title_stateContainer">
                            <p className="cards__contactContainer_titleContainer_title_stateContainer_state">{props.type === "provider" ? "fournisseur" : props.type}</p>
                        </div>
                    </div>
                    <p className="cards__contactContainer_society">{props.society}</p>
                    <div className="cards__contactContainer_address"><FontAwesomeIcon icon={faHouse}/> <p>{props.adress} {props.CP} <span className={"cards__contactContainer_address_city"}>{props.city}</span></p></div>
                    <div className="cards__contactContainer_phone-number">
                        {
                            phone ? (
                                <>
                                    <FontAwesomeIcon icon={faPhone}/>
                                    <p className="ttc">{props.mobilePhone}</p>
                                </>
                            ) : (
                                <>
                                    <FontAwesomeIcon icon={faPhone}/>
                                    <p>Pas de numéro de téléphone renseigné</p>
                                </>
                            )
                        }

                    </div>
                </div>
            </div>
        </section>
    )
}

export default ContactCard;