import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {faPlus} from "@fortawesome/free-solid-svg-icons";

export default function DropDownButton({ items, dropDownTitle }) {

    return (
        <div className="toolbar__container_tools_dropdown">
            <div className="toolbar__container_tools_dropdown_dropdown-toggle button">
                <p>{dropDownTitle ? dropDownTitle : "Plus d'actions"}</p>
                <FontAwesomeIcon icon={faPlus} />
            </div>
            <div className="toolbar__container_tools_dropdown_dropdown-menu dropdown-menu">
                {Object?.entries(items).map(([key, value]) => (
                    <div key={key} href="" onClick={value.handleClick}>
                      {value.icon &&<FontAwesomeIcon icon={value.icon} />}
                        <p>{value.content}</p>
                    </div>
                ))}
            </div>
        </div >

    );
}
