import {useEffect, useState} from "react";

export default function BasicInput({placeholder, className, refs, name, type, id, labelName, autoComplete, readOnly, defaultValue}) {

    const [inputWidth, setInputWidth] = useState('auto');

    useEffect(() => {
        // eslint-disable-next-line no-unused-expressions
        refs !== undefined && defaultValue !== undefined ? refs.current.value = defaultValue : null;
        const span = document.createElement('span');
        span.style.visibility = 'hidden';
        span.style.whiteSpace = 'nowrap';
        if (refs && 1 === 2) {
            span.style.fontSize = window?.getComputedStyle(refs?.current).fontSize;
            span.style.fontFamily = window?.getComputedStyle(refs?.current).fontFamily;
        }
        span.textContent = placeholder;

        document.body.appendChild(span);
        setInputWidth(`${span.offsetWidth}px`);
        document.body.removeChild(span);
    }, [defaultValue, placeholder, refs]);

    return (
        <>
            <div className={`basicInputContainer ${className}`}>
                {labelName &&
                    <label className="basicInputContainer__label" htmlFor={id}>{labelName}</label>
                }
                <input autoComplete={autoComplete} id={id} style={{width: inputWidth}} name={name} type={type} placeholder={placeholder}
                       ref={refs} className="basicInputContainer__button" readOnly={readOnly} data-placeholder="Date de début"/>
            </div>
        </>
    )
}