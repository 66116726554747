import {Pagination} from "@mui/material";
import {useContext, useEffect, useRef, useState} from "react";
import {useNavigate} from "react-router-dom";
import ContactCard from "../../components/customer/contacts/contactCard";
import {faFileMedical} from "@fortawesome/free-solid-svg-icons";
import {GetApiContext} from "../../context/apiCalls/getApiCalls";
import MainHeader from "../../components/addOns/headers/MainHeader";
import StateFilter from "../../components/addOns/filterComponent/stateFilter";
import {PostApiContext} from "../../context/apiCalls/postApiCalls";
import Loader from "../../components/addOns/loader";
import SearchComponent from "../../components/addOns/filterComponent/searchComponent";

function ContactList({ selectMode = false, onSelectContact, setContacts}) {

    const navigate = useNavigate();
    const [contactList, setContactList] = useState();
    const [contactState, setContactState] = useState("all");
    const [loading, setLoading] = useState(true);
    const [sort] = useState();

    const [currentPage, setCurrentPage] = useState(1);
    const [perPage, setPerPage] = useState();
    const [nbPages, setNbPages] = useState();
    const [filterLetter, setFilterLetter] = useState("");

    const firstNameRef = useRef();
    const lastNameRef = useRef();
    const societyRef = useRef();
    const mobilePhoneRef = useRef();
    const cityRef = useRef();

    const getApiCalls = useContext(GetApiContext)
    const postApiCalls = useContext(PostApiContext)

    const [bla, setBla] = useState(false);

    const clearFilter = () => {
        setBla(true);
        setFilterLetter("");
        firstNameRef.current.value = "";
        lastNameRef.current.value = "";
        societyRef.current.value = "";
        mobilePhoneRef.current.value = "";
        cityRef.current.value = "";
        setContactList(null);
    };

    useEffect(() => {
        setLoading(true);
        getApiCalls.getContactsList(contactState, currentPage, perPage, sort)
            .then((response) => {
                // console.log("response contact list :", response);
                if (
                    !response.listContacts ||
                    response.listContacts.length === 0
                ) {
                    setFilterLetter("");
                } else {
                    setContactList(response.listContacts);
                }
                setLoading(false);

                setPerPage(response.perPage);
                setNbPages(response.nbPages);
                if (response.nbPages < response.currentPage) {
                    setCurrentPage(response.nbPages);
                } else {
                    setCurrentPage(response.currentPage);
                }
                if (setContacts) {
                    setContacts(response.listContacts);
                }
            })
            .catch((error) => {
                console.log(error);
            });


        setBla(false);
    }, [filterLetter, bla, currentPage, perPage, sort, getApiCalls, contactState, setContacts]);

    const changePage = (e, value) => {
        setCurrentPage(value);
    };

    const changePerPage = (e) => {
        setPerPage(e.target.value);
        setCurrentPage(1);
    };

    const openContact = (contactId) => {
        console.log("oui1",contactId, selectMode, onSelectContact)
        if (selectMode) {
            console.log("oui")
            onSelectContact(contactId);
        } else {
            navigate(`/contactsList/contact/${contactId}`);
        }
    }

    const onValidFilter = async (e) => {
        e?.preventDefault();
        setContactList(null);
        setLoading(true);

        const values = {
            lastName: lastNameRef.current.value,
            firstName: firstNameRef.current.value,
            society: societyRef.current.value,
            city: cityRef.current.value,
            mobilePhone: mobilePhoneRef.current.value,
            filterLetter: filterLetter,
        };

        postApiCalls.postContactsListFilter(contactState, values)
        //await axios.post(`${process.env.REACT_APP_CONTACT_LIST_FILTER}&state=${contactState}`, values, customHeaders)
            .then((response) => {
                console.log(response.listContacts);
                console.log(response);
                setContactList(response.listContacts);
                // console.log("contactsList", contactsList);
                setLoading(false);
            })
            .catch((error) => {
                console.log(error);
            });
    };

    const objectButtons = [
        {
            title: "Nouveau contact",
            className: "",
            icon: faFileMedical,
            action: () => navigate("/contactsList/contact"),
            showByRoleId: true
        },
    ]

    const stateObject = [
        {valid: "all", name: "tous les contacts"},
        {valid: "customer", name: "vos clients"},
        {valid: "provider", name: "vos fournisseurs"},
        {valid: "prospect", name: "vos prospects"},
    ];
    return (
        <>
            {loading && <Loader/>}
            <div className="">
                <MainHeader headerTitle={"CONTACTS"} objectButtons={objectButtons}/>
                <div className="listPage">
                    <StateFilter setState={setContactState} state={contactState} stateObject={stateObject} />

                    <SearchComponent
                        refresh={clearFilter}
                        onSearchSelectChange={onValidFilter}
                        onSearchSubmit={onValidFilter}
                        contentObject={{
                            ContentName: "CONTACT",
                            Content: [
                                {name: "lastName", title: "Nom", type: "text", ref: lastNameRef},
                                {name: "firstName", title: "Prénom", type: "text", ref: firstNameRef},
                                {name: "mobilePhone", title: "Mobile", type: "text", ref: mobilePhoneRef},
                                {name: "city", title: "Ville", type: "text", ref: cityRef},
                                {name: "society", title: "Société", type: "text", ref: societyRef},
                            ]
                        }}
                        />
                    <div style={{marginTop: "2rem"}} className="cards-list">
                        {contactList &&
                            contactList.map((contact, k) => {
                                return (
                                    <ContactCard
                                        key={k}
                                        contactId={contact.id}
                                        refcontact={contact.ref}
                                        firstName={contact.firstName}
                                        lastName={contact.lastName}
                                        society={contact.society}
                                        adress={contact.adress1}
                                        CP={contact.postal}
                                        city={contact.city}
                                        mobilePhone={contact.mobilePhone}
                                        function={contact.function}
                                        type={contact.type}
                                        openContact={openContact}
                                    />
                                );
                            })}
                    </div>
                </div>
                {!loading && (
                    <div className="pagination">
                        <label>Éléments par page </label>
                        <select
                            name="perPage"
                            value={perPage}
                            onChange={(e) => changePerPage(e)}
                        >
                            <option value="10">10</option>
                            <option value="50">50</option>
                            <option value="100">100</option>
                        </select>
                        <Pagination
                            count={nbPages}
                            variant="outlined"
                            shape="rounded"
                            showFirstButton
                            showLastButton
                            onChange={(e, value) => changePage(e, value)}
                            className="select-page"
                            page={currentPage}
                        />
                    </div>
                )}
                <a href="#!" alt="" className="add-button">
                    <i className="fa-solid fa-plus"></i>
                </a>
            </div>
        </>
    );
}

export default ContactList;
