import {createContext, useEffect, useState} from "react";

export const ContactPopContext = createContext(null);

export default function ContactPopProvider(props) {

  const [showContactPopup, setShowContactPopup] = useState(false);

  // Gestion de l'ajout et retrait de la classe pour désactiver le scroll
  useEffect(() => {
    const mainElement = document.getElementsByClassName('main')[0]; // Sélectionne le conteneur principal
    // console.log("showContactPopup", showContactPopup);
    if (mainElement === undefined) return;
    if (showContactPopup) {
      mainElement.style.position = 'fixed';
    } else {
      mainElement.style.position = '';
    }

    // Nettoyage de l'effet quand le composant est démonté
    return () => {
      mainElement.style.position = '';
    };
  }, [showContactPopup]);

    return (
        <ContactPopContext.Provider value={{
            showContactPopup,
            setShowContactPopup
        }}>
            {props.children}
        </ContactPopContext.Provider>
    );
}

