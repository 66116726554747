import React from "react";
function TotalAmonts({totalTTC,totalHT,totalVAT}){

const formatNumber = (number) =>{
    return parseFloat(number).toFixed(2);
}

    return(
        <div className="totalamonts">
            
            <table>
                <thead>
                    <tr>
                        {totalHT ? <td>Montant Total HT</td> : <></>}
                        {totalVAT ? <td>Montant Total TVA</td> : <></>}
                        {totalTTC ? <td>Montant Total TTC</td> : <></>}
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        {totalHT ? <td>{formatNumber(totalHT)} €</td> : <></>}
                        {totalVAT ? <td>{formatNumber(totalVAT)} €</td> : <></>}
                        {totalTTC ? <td>{formatNumber(totalTTC)} €</td> : <></>}
                    </tr>
                </tbody>
            </table>


        </div>
    );
}

export default TotalAmonts;