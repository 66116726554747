import PriceRecap from "./priceReacp";
import LinkedDepositList from "../../../deposit/LinkedDepositList";

export default function RecapContainer({linkDeposit, handleSubmitInvoice,invoiceId, totalTtc, totalHt ,discount, handleDiscountChange, handleDiscountBlur ,totalInvoice, vatList, deleteDeposit, createDeposit, discountPrice}) {
    const onDepositLinkClick = (e, path) => {
        handleSubmitInvoice(e, path)
    }

    return (
        <section className="reacpContainer">
            <PriceRecap totalTtc={totalTtc} totalHt={totalHt} discount={discount} discountPrice={discountPrice} handleDiscountChange={handleDiscountChange} handleDiscountBlur={handleDiscountBlur} totalInvoice={totalInvoice} vatList={vatList} />
          {linkDeposit &&
            <LinkedDepositList linkDeposit={linkDeposit} onSubmit={onDepositLinkClick} deleteDeposit={deleteDeposit} createDeposit={createDeposit} parentPath={`/invoicesList/invoice/${invoiceId}`} />
          }
        </section>
    )
}