import CataloguesList from "../../../routes/catalog/CataloguesList";

export default function CatalogPop({setShowCatalogues, handleSelectedArticle}) {

  return (
      <>
        <div onClick={() => setShowCatalogues(false)} className="grayBlur"></div>
        <div
            style={{
              display: 'flex',
              justifyContent: 'center', // Centrage horizontal
              alignItems: 'center', // Centrage vertical
              position: 'fixed',
              top: 0,
              left: 0,
              width: '100vw',
              height: '100vh',
              zIndex: 99999,
            }}
        >
          <div style={{
            top: '0',
            left: '250px',
            width: 'calc(90% - 250px)',
            height: '90vh',
            backgroundColor: "#fff",
            zIndex: 99999,
            position: "fixed",
            margin: "5vh 5%",
            overflow: "auto"
          }}>

            <CataloguesList isPopup={true} onSelectArticle={handleSelectedArticle}/>
          </div>
        </div>
      </>
  )
}