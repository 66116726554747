import {useNavigate} from 'react-router-dom';
import Card from "../../components/addOns/card";
import {useContext, useEffect, useRef, useState} from 'react';
import Pagination from '../../components/addOns/Pagination';
import TotalAmonts from '../../components/addOns/TotalAmonts';
import {
    faCircleCheck,
    faCopy,
    faDownload,
    faFileMedical,
    faPaperPlane,
    faPrint
} from "@fortawesome/free-solid-svg-icons";
import {GetApiContext} from "../../context/apiCalls/getApiCalls";
import Loader from "../../components/addOns/loader";
import MainHeader from "../../components/addOns/headers/MainHeader";
import StateFilter from "../../components/addOns/filterComponent/stateFilter";
import SearchComponent from "../../components/addOns/filterComponent/searchComponent";
import SortByFilter from "../../components/addOns/filterComponent/SortByFilter";
import ValidPop from "../../components/addOns/popComponents/validPop";
import {PostApiContext} from "../../context/apiCalls/postApiCalls";

function CreditList() {

    const firstNameRef = useRef();
    const lastNameRef = useRef();
    const societyRef = useRef();
    const creditRef = useRef();
    const interloSearch = useRef();

    const [dateRange, setDateRange] = useState([]);
    const [sort, setSort] = useState('datedown')
    const [creditId, setCreditId] = useState();
    const [interlocutors] = useState();
    const [loading, setLoading] = useState(true);
    const [creditsList, setCreditsList] = useState();
    const [creditState, setCreditState] = useState('all');
    const [listRefresh, setListRefresh] = useState(false);

    const [currentPage, setCurrentPage] = useState(1);
    const [perPage, setPerPage] = useState();
    const [nbPages, setNbPages] = useState();
    const [totalTTC, setTotalTTC] = useState(0);
    const [totalHT, setTotalHT] = useState(0);
    const [totalVAT, setTotalVAT] = useState(0);
    const [showValidPop, setShowValidPop] = useState(false);

    const getApiContext = useContext(GetApiContext);
    const postApiCalls = useContext(PostApiContext);
    const navigate = useNavigate();

    useEffect(() => {
        // console.log("reload")
        setLoading(true);
        setListRefresh(false);
        getApiContext.getCreditList(creditState, sort, currentPage, perPage)
            .then(response => {
                // console.log(response)
                setCreditsList(response.creditsList);
                setPerPage(response.perPage);
                setNbPages(response.nbPages);
                setTotalTTC(response.totalAmonts.total_ttc);
                setTotalHT(response.totalAmonts.total_ht);
                setTotalVAT(response.totalAmonts.total_vat);
                if (response.nbPages < response.currentPage) {
                    setCurrentPage(response.nbPages);
                } else {
                    setCurrentPage(response.currentPage);
                }

                setLoading(false)
            })
            .catch(error => {
                console.log(error);
            })
    }, [creditState, sort, currentPage, perPage, listRefresh, getApiContext]);

    // console.log("log de creditsList avant", creditsList);
    const onValidFilter = async (e) => {
        e.preventDefault();

        setLoading(true)
        setCreditsList(null)

        const values = {
            lastName: lastNameRef.current?.value,
            firstName: firstNameRef.current?.value,
            society: societyRef.current?.value,
            interlocuteur: interloSearch.current?.value,
            startDate: dateRange[0] ? dateRange[0].toLocaleDateString().split('T')[0] : null,
            endDate: dateRange[1] ? dateRange[1].toLocaleDateString().split('T')[0] : null,
            creditRef: creditRef.current?.value,
        }
        console.log("values", values)
        postApiCalls.postCreditFilterList(values, creditState, sort, currentPage, perPage)
            .then(response => {
                console.log("post liste", response);
                setCreditsList(response.creditsList)
                setTotalTTC(response.totalAmonts.total_ttc);
                setTotalHT(response.totalAmonts.total_ht);
                setTotalVAT(response.totalAmonts.total_vat);
                setPerPage(response.perPage);
                setNbPages(response.nbPages);
                setCurrentPage(response.currentPage);
                setLoading(false)
            })
            .catch(error => {
                console.log(error);
            })

    }

    const refresh = () => {
        const formulaire = document.getElementById("searchFilterForm");
        formulaire.reset();
        setDateRange([]);
        setListRefresh(true);
    }

    const handleOpenValidItem = (id) => {
        setShowValidPop(true);
        setCreditId(id);
    }

    const handleOpenCredit = () => {
        navigate(`/creditList/credit/${creditId}`)
    }

  function duplicateCredit(e) {
    const dupId = e.target.parentNode.parentNode.id;
    getApiContext.getCredit("", dupId).then(response => {
      console.log(response)
      let newCredit = response?.credit;
      navigate(newCredit ? "/creditList/credit/" + newCredit.id : "/creditList");
    })
  }
    return (
        <>
            {loading && <Loader />}
            {showValidPop &&
                <ValidPop bgcAction={(e) => setShowValidPop(false)} showValidPop={showValidPop}
                          title={"Continuer vers cet avoir ?"}
                          desc={"Cet avoir est validé, voulez-vous vraiment la modifier ?"}
                          buttonsObject={[
                              {text: "Retour", className: "backButton", action: (e) => setShowValidPop(false)},
                              {text: "Continuer", className: "continueButton", action: handleOpenCredit},
                          ]}/>
            }
            <MainHeader
                headerTitle={"AVOIRS"}
                objectButtons={[
                    {title: "Nouvel avoir", className: "", icon: faFileMedical, action: () => navigate("/creditList/credit"), showByRoleId: true},
                ]}
            />
                <div className='listPage'>
                    <div className='listPage__filterContainer'>
                        <StateFilter
                            setState={setCreditState} state={creditState}
                            stateObject={[
                                { valid: "all", name: "tous les avoirs" },
                                { valid: "paid", name: "vos avoirs payés" },
                                { valid: "unpaid", name: "vos avoirs impayés" },
                                { valid: "draft", name: "vos brouillons" },
                                { valid: "canceled", name: "vos avoirs annulés" },
                            ]}
                        />
                        <SearchComponent
                            contentObject={{
                                ContentName: "AVOIR",
                                Content: [
                                    { name: "firstName", title: "Prénom", type: "text", ref: firstNameRef},
                                    { name: "lastName", title: "Nom", type: "text", ref: lastNameRef },
                                    { name: "dateRange", title: "Plage de date", type: "dateRangePicker", ref: dateRange},
                                    { name: "society", title: "Société", type: "text", ref: societyRef },
                                ]
                            }}
                            refresh={refresh}
                            onSearchSubmit={onValidFilter}
                            interlocutor={interlocutors}
                            dateRange={dateRange}
                            setDateRange={setDateRange}
                        />
                        <SortByFilter
                            setSort={setSort}
                            sortOptions={{
                                options: [
                                    { value: "datedown", content: "Date décroissante" },
                                    { value: "dateup", content: "Date croissante" },
                                    { value: "refup", content: "Références croissantes" },
                                    { value: "refdown", content: "Références décroissantes" },
                                ]
                            }}
                        />
                    </div>
                        {creditsList && creditsList.map((credit, k) => {
                            const element = {
                                id: credit.creditId,
                                valid: credit.valid,
                                state: credit.state,
                                date: credit.creditDate,
                                ref: credit.ref,
                                subject: credit.subject,
                                lastName: credit.LastName,
                                firstName: credit.FirstName,
                                society: credit.society,
                                totalHt: credit.totalHT,
                                totalTtc: credit.totalTTC,
                                directionPath: "/creditList/credit/",
                                initials: credit.DD,
                            }
                                // console.log(credit.totalHT);
                                return (
                                    <Card
                                        key={k}
                                        tableName='credit'
                                        setListRefresh={setListRefresh}
                                        deleteEndPoint='c-deactive-credit'
                                        element={element}
                                        otherButtonList={[
                                            {title: "Télécharger", icon: faDownload, className: "edit", action: () => console.log("hello")},
                                            {title: "Envoyer", icon: faPaperPlane, className: "delete", action: () => console.log("hello")},
                                            {title: "imprimer", icon: faPrint, className: "edit", action: () => console.log("hello")},
                                            {title: "dupliquer", icon: faCopy, className: "dup", action: (e) => duplicateCredit(e)},
                                        ]}
                                        stateButtonsList={[
                                            {title: "Payé", icon: faCircleCheck, color: "stateGreen", name: "paid"},
                                            {title: "Non Payé", icon: faCircleCheck, color: "stateOrange", name: "unpaid"},
                                            {title: "Annulé", icon: faCircleCheck, color: "stateRed", name: "canceled"},
                                        ]}
                                        handleOpenValidItem={handleOpenValidItem}
                                        roleVerif={true}

                                    />
                                )
                            })
                        }
                </div>
                <TotalAmonts
                    totalTTC={totalTTC}
                    totalHT={totalHT}
                    totalVAT={totalVAT}
                />
          <Pagination
              perPage={perPage}
              setPerPage={setPerPage}
              setCurrentPage={setCurrentPage}
              currentPage={currentPage}
              nbPages={nbPages}
          />
        </>
    )
}

export default CreditList;