import React from "react";

const Pagination = ({perPage, setPerPage, setCurrentPage, currentPage, nbPages }) => {

  const changePerPage = (e) => {
    if(setPerPage) {
      setCurrentPage(1);
      setPerPage(e.target.value);
    }
  };

  return (

      <div className="pagination">
        <label>Éléments par page </label>
        <select name='perPage' value={perPage} onChange={(e) => changePerPage(e)}>
          <option value="25">25</option>
          <option value="50">50</option>
          <option value="100">100</option>
          <option value="300">300</option>
          <option value="500">500</option>
        </select>
        {/* Double left arrow button to go to the first page */}
        <div className="pagination">
          <button
              onClick={() => setCurrentPage(1)}
              disabled={currentPage === 1}
              className={currentPage === 1 ? "disabled" : ""}>
            &lt;&lt;
          </button>

          {/* Current page - 1 button */}
          <button
              onClick={() => setCurrentPage(currentPage - 1)}
              disabled={currentPage === 1}
              className={currentPage === 1 ? "disabled" : ""}>
            {currentPage !== 1 ? currentPage - 1 : "-"}
          </button>

          {/* Current page button */}
          <button className="current-page">
            {currentPage}
          </button>

          {/* Current page + 1 button */}
          <button
              onClick={() => setCurrentPage(currentPage + 1)}
              disabled={nbPages === currentPage}
              className={currentPage === nbPages ? "disabled" : ""}>
            {nbPages !== currentPage ? currentPage + 1 : "-"}
          </button>

          {/* Double right arrow button to go to the last page */}
          <button
              onClick={() => setCurrentPage(nbPages)}
              disabled={currentPage === nbPages}
              className={currentPage === nbPages ? "disabled" : ""}>
            &gt;&gt;
          </button>
        </div>
      </div>
  );
};

export default Pagination;