import { useNavigate } from 'react-router-dom';
import {useEffect, useState, useCallback, useRef, useContext} from 'react';
import Pagination from '../../components/addOns/Pagination';
import TotalAmonts from '../../components/addOns/TotalAmonts';
import SearchComponent from "../../components/addOns/filterComponent/searchComponent";
import StateFilter from '../../components/addOns/filterComponent/stateFilter';
import Card from "../../components/addOns/card";
import MainHeader from "../../components/addOns/headers/MainHeader";
import Loader from "../../components/addOns/loader";
import {
    faCircleCheck,
    faCopy,
    faDownload,
    faFileMedical,
    faPaperPlane,
    faPrint,
    faRightLeft
} from "@fortawesome/free-solid-svg-icons";
import SortByFilter from "../../components/addOns/filterComponent/SortByFilter";
import ValidPop from "../../components/addOns/popComponents/validPop";
import {GetApiContext} from "../../context/apiCalls/getApiCalls";
import {PostApiContext} from "../../context/apiCalls/postApiCalls";
import {useAuthState} from "../../context/authContext";
import roleVerify from "../../utils/roleVerify";

function QuotesList(callback, deps) {

    const {roleId} = useAuthState();
    const roleList = ["1", "2", "3", "4", "5", "6"];
    const roleVerif = roleVerify(roleList, roleId)

    const navigate = useNavigate();
    const getApiContext = useContext(GetApiContext);
    const postApiContext = useContext(PostApiContext);


    const [quotesList, setQuotesList] = useState();
    const [interlocutors, setInterlocutors] = useState();
    const [quoteState, setQuoteState] = useState('all');
    const [sort, setSort] = useState('datedown')
    const [nbPages, setNbPages] = useState();
    const [currentPage, setCurrentPage] = useState(1);
    const [perPage, setPerPage] = useState();
    const [totalTTC, setTotalTTC] = useState(0);
    const [totalHT, setTotalHT] = useState(0);
    const [totalVAT, setTotalVAT] = useState(0);
    const [loading, setLoading] = useState(false);
    const [showValidPop, setShowValidPop] = useState(false);
    const [quoteValidId, setQuoteValidId] = useState();

    const firstNameRef = useRef();
    const lastNameRef = useRef();
    const quoteFilterRef = useRef();
    const [dateRange, setDateRange] = useState([]);
    const societyRef = useRef();

    const [interlocutorSearch, setInterlocutorSearch] = useState();

    useEffect(() => {
        document.querySelector("body").style.overflow = showValidPop ? "hidden" : "auto";
    }, [showValidPop])

    const onValidFilter = useCallback(async (e) => {
        e?.preventDefault();

        setQuotesList(null)
        setLoading(true)

        const values = {
            lastName: lastNameRef.current?.value,
            firstName: firstNameRef.current?.value,
            society: societyRef.current?.value,
            interlocuteur: interlocutorSearch,
            startDate: dateRange && dateRange[0] ? dateRange[0].toLocaleDateString().split('T')[0] : null,
            endDate: dateRange && dateRange[1] ? dateRange[1].toLocaleDateString().split('T')[0] : null,
            quoteRef: quoteFilterRef.current?.value,
        }

        try {
            const response = await postApiContext.postQuotesList(values, sort, quoteState, currentPage, perPage);
            setQuotesList(response.quotesList);
            setInterlocutors(response.listInterlocutors);
            setTotalHT(response.totalAmonts.total_ht);
            setTotalTTC(response.totalAmonts.total_ttc);
            setTotalVAT(response.totalAmonts.total_vat);
            setPerPage(response.perPage);
            setNbPages(response.nbPages);
            setCurrentPage(response.currentPage);
        } catch (error) {
            console.error(error);
        } finally {
            setLoading(false);
        }

    },[interlocutorSearch, dateRange, postApiContext, sort, quoteState, currentPage, perPage]);

    useEffect(() => {
        onValidFilter();
    }, [onValidFilter]);

    const onSearchSelectChange = (e) => {
        console.log(e.target.value);
        setInterlocutorSearch(e.target.value);
    }

    const transferQuoteToInvoice = (e) => {
        let quoteId = e?.target?.parentElement?.parentElement?.id;
        getApiContext?.transferQuoteToInvoice(quoteId).then(response => {
            //console.log(response);
            navigate("/invoicesList/invoice/" + response.invoice?.id);
        });
    }

    const duplicateQuote = (e) => {
        const dupId = e.target.parentNode.parentNode.id;
        getApiContext.getQuote("", dupId).then(response => {
            if (response?.quote){
                const quote = response?.quote;
                navigate(`/quotesList/quote/${quote?.id}`);
            }
        });
    }

    const contentObject = {
        ContentName: "DEVIS",
        Content: [
            { name: "firstName", title: "Prénom", type: "text", ref: firstNameRef },
            { name: "lastName", title: "Nom", type: "text", ref: lastNameRef },
            { name: "refDevis", title: "Référence Devis", type: "text", ref: quoteFilterRef },
            { name: "dateRange", title: "Plage de date", type: "dateRangePicker", ref: dateRange},
            { name: "society", title: "Société", type: "text", ref: societyRef },
        ]
    }

    const stateObject = [
        { valid: "all", name: "tous les devis" },
        { valid: "signed", name: "vos devis signés" },
        { valid: "validated", name: "vos devis finalisés" },
        { valid: "draft", name: "vos brouillons" },
        { valid: "canceled", name: "vos devis annulés" },
    ];

    const otherButtonList = [
        {title: "Télécharger", icon: faDownload},
        {title: "Envoyer", icon: faPaperPlane},
        {title: "imprimer", icon: faPrint},
        {title: "dupliquer", icon: faCopy, action: (e) => duplicateQuote(e)},
        {title: "Transférer en facture", icon: faRightLeft, action: (e) => transferQuoteToInvoice(e)}
    ]

    const stateButtonsList = [
        {title: "Finalisé", icon: faCircleCheck, color: "stateGreen", name: "validated"},
        {title: "Signé", icon: faCircleCheck, color: "stateOrange", name: "signed"},
        {title: "Annulé", icon: faCircleCheck, color: "stateRed", name: "canceled"},
    ]


    const refresh = () => {
        const formulaire = document.getElementById("searchFilterForm");
        formulaire.reset();
        setDateRange([]);
        setInterlocutorSearch(null);
    }

    const openEmptyQuote = () => {
        navigate("/quotesList/quote/")
    }

    const handleOpenValidQuote = (id) => {
        setShowValidPop(true)
        setQuoteValidId(id)
    }

    const handleOpenQuote = () => {
        navigate(`/quotesList/quote/${quoteValidId}`)
    }

    const objectButtons = [
        {title: "Nouveau devis", className: "", icon: faFileMedical, action: openEmptyQuote, showByRoleId: true},
    ]

    const sortOptions = {
        options: [
            { value: "datedown", content: "Date décroissante" },
            { value: "dateup", content: "Date croissante" },
            { value: "refup", content: "Références croissantes" },
            { value: "refdown", content: "Références décroissantes" },
        ]
    }

    const buttonsObject = [
        {text: "Retour", className: "backButton", action: (e) => setShowValidPop(false)},
        {text: "Continuer", className: "continueButton", action: handleOpenQuote},
    ]

    return (
        <>
            <MainHeader headerTitle={"DEVIS"} objectButtons={objectButtons}/>
            {
                showValidPop &&
                <ValidPop bgcAction={(e) => setShowValidPop(false)} showValidPop={showValidPop} title={"Continuer vers ce devis ?"} desc={"Ce devis est verrouillé car il a été transféré en facture, voulez-vous le déverrouiller ?"} buttonsObject={buttonsObject}/>
            }
            {
                loading &&
                <Loader/>
            }

            <section className='listPage'>

                <div className="listPage__filterContainer">
                    <StateFilter setState={setQuoteState} stateObject={stateObject} state={quoteState}/>
                    <SearchComponent
                        refresh={refresh}
                        onSearchSelectChange={onSearchSelectChange}
                        onSearchSubmit={onValidFilter}
                        contentObject={contentObject}
                        interlocutor={interlocutors}
                        dateRange={dateRange}
                        setDateRange={setDateRange}
                    />
                    <SortByFilter setSort={setSort} sortOptions={sortOptions}/>
                </div>

                    {quotesList && quotesList.map((quote, k) => {
                        const element = {
                            id: quote.quoteId,
                            valid: quote.quoteValid,
                            state: quote.quoteState,
                            date: quote.quoteDate,
                            ref: quote.quoteRef,
                            subject: quote.quoteSubject,
                            lastName: quote.societyContactLastName,
                            firstName: quote.societyContactFirstName,
                            society: quote.societyContactSociety,
                            totalHt: quote.quoteTotalHt,
                            totalTtc: quote.quoteTotalTtc,
                            directionPath: "/quotesList/quote/",
                            initials: quote.userInitials,
                        }

                        return (
                            <Card roleVerif={roleVerif} key={k} element={element} tableName={"quote"} handleOpenValidItem={handleOpenValidQuote}
                                stateButtonsList={stateButtonsList}
                                  deleteEndPoint={"c-deactive-quote"} otherButtonList={otherButtonList}/>
                        )
                    })
                    }
                    <TotalAmonts
                        totalTTC={totalTTC}
                        totalHT={totalHT}
                        totalVAT={totalVAT}
                    />
                <Pagination
                    perPage={perPage}
                    setPerPage={setPerPage}
                    setCurrentPage={setCurrentPage}
                    currentPage={currentPage}
                    nbPages={nbPages}
                />
            </section>
        </>
);
}

export default QuotesList;
