import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faChevronDown, faChevronUp} from "@fortawesome/free-solid-svg-icons";
import {useEffect, useState} from "react";
import Decimal from "decimal.js";
import DeletButton from "../../../../addOns/deletButton";

export default function InvoiceLine({setRefresh, unitsList, handleLineChange, vatList, line, handleMoveLine, linesLength, deleteLineByNotId, index}) {

    const [lineUpdate, setLineUpdate] = useState({})
    const [vatSelected, setVatSelected] = useState(0);
    const [unitSelected, setUnitSelected] = useState(0);
    const [quantity, setQuantity] = useState(0);
    const [unitPrice, setUnitPrice] = useState(0);
    const [total, setTotal] = useState(0);
    useEffect(() => {
        setLineUpdate(line)
        setQuantity(line.quantity)
        setUnitPrice(line.unitPrice)
    }, [line])

    useEffect(() => {

        const aDecimal = new Decimal(quantity ? quantity : 0);
        const bDecimal = new Decimal(unitPrice ? unitPrice : 0);

        setTotal(aDecimal.times(bDecimal)?.toString());
    }, [quantity, unitPrice]);


    const testBla = (e) => {
        const vat = vatList.find(vat => vat.id === e.target.value);
        setVatSelected(vat.id);
    }

    const validPopContent = {
        title: "Suppression",
        desc: `Êtes-vous sûr de vouloir supprimer cette ligne ?`,
    }

    return (
        <div className="invoiceLine" id={line.numero}>
            <div className="invoiceLine__leftContent">
                <div className="invoiceLine__leftContent_header">
                    <div className="invoiceLine__leftContent_header_leftPart">
                        <p className="invoiceLine__leftContent_header_leftPart_number">{index+1}</p>
                        <button className='invoiceLine__leftContent_header_leftPart_refreshButton'>Effacer</button>
                        <DeletButton  deleteItemByNotId={deleteLineByNotId} setRefresh={setRefresh} url={"c-delete-invoice-line"} id={line.id} className={"invoiceLine__leftContent_header_leftPart_deleteButton"} validPopContent={validPopContent}/>
                        {/*<button className="invoiceLine__leftContent_header_leftPart_deleteButton"><FontAwesomeIcon*/}
                        {/*    icon={faTrash}/></button>*/}
                    </div>
                    <div className="invoiceLine__leftContent_header_arrowContainer">
                        <FontAwesomeIcon onClick={() => handleMoveLine(lineUpdate.numero, "up")} style={
                            line.numero <= 1 && {opacity: 0.3, cursor: 'not-allowed'}
                        } className='invoiceLine__leftContent_header_arrowContainer_arrow'
                                         icon={faChevronUp}/>
                        <FontAwesomeIcon onClick={() => handleMoveLine(lineUpdate.numero, "down")} style={
                            line.numero === linesLength.toString() && {opacity: 0.3, cursor: 'not-allowed'}
                        } className='invoiceLine__leftContent_header_arrowContainer_arrow'
                                         icon={faChevronDown}/>
                    </div>
                </div>
                <textarea onBlur={(e) => handleLineChange(e, line.id)} defaultValue={lineUpdate?.name}
                          className="invoiceLine__leftContent_textarea" name="name"
                          id="articleDesc" cols="30" rows="10"
                          placeholder="Description de l'article"></textarea>
            </div>
            <div className='invoiceLine__rightContent'>
                <div className="invoiceLine__rightContent_inputContainer">
                    <label className="invoiceLine__rightContent_inputContainer_label" htmlFor="unit">Unité</label>
                    <select onChange={(e) => setUnitSelected(e.target.value)}
                            onBlur={(e) => handleLineChange(e, line.id)}
                            value={unitSelected ?? line.unitId ?? 0}
                            className='invoiceLine__rightContent_inputContainer_input' name="unitId" id="unit">
                        {
                            unitsList && unitsList.length > 0 &&
                            unitsList.map((unit, index) => {
                                return (
                                    <option className='invoiceLine__rightContent_inputContainer_input_option'
                                            key={index} value={unit.id}>{unit.unit_name}
                                    </option>
                                )
                            })
                        }
                        <option className='invoiceLine__rightContent_inputContainer_input_option' value="1">Pièce
                        </option>
                    </select>
                </div>
                <div className='invoiceLine__rightContent_inputContainer'>
                    <label className="invoiceLine__rightContent_inputContainer_label"
                           htmlFor="quantity">Quantité</label>
                    <input onBlur={(e) => handleLineChange(e, line.id)} min="O"
                           onChange={(e) => setQuantity(e.target.value)}
                           onWheel={(e) => e.target.blur()}
                           defaultValue={lineUpdate?.quantity}
                           className='invoiceLine__rightContent_inputContainer_input'
                           id="quantity" name="quantity" type="number" placeholder="20"/>
                </div>
                <div className='invoiceLine__rightContent_inputContainer'>
                    <label className="invoiceLine__rightContent_inputContainer_label" htmlFor="htPrice">Prix HT</label>
                    <input onBlur={(e) => handleLineChange(e, line.id)} min="0"
                           onChange={(e) => setUnitPrice(e.target.value)}
                           onWheel={(e) => e.target.blur()}
                           defaultValue={lineUpdate.unitPrice}
                           className='invoiceLine__rightContent_inputContainer_input'
                           id="htPrice" name="unitPrice" type="number" placeholder="12,30 €"/>
                </div>
                <div className="invoiceLine__rightContent_inputContainer">
                    <label className="invoiceLine__rightContent_inputContainer_label" htmlFor="vat">TVA</label>
                    <select onBlur={(e) => handleLineChange(e, line.id)} onChange={testBla}
                            value={vatSelected ? vatSelected : lineUpdate?.vatId || 0}
                            className='invoiceLine__rightContent_inputContainer_input' name="vatId" id="vat">
                        {
                            vatList && vatList.length > 0 &&
                            vatList.map((vat, index) => {
                                return (
                                    <option className='invoiceLine__rightContent_inputContainer_input_option'
                                            key={index} value={vat.id}>{vat.rate}%
                                    </option>
                                )
                            })
                        }
                    </select>
                </div>
                <h4 className="invoiceLine__rightContent_total">montant ht : <span>{total} €</span></h4>
            </div>
        </div>
    )
}